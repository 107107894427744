import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  
    private updateLoaderSource = new Subject<boolean>();

  // Observable string streams
  updateLoader$ = this.updateLoaderSource.asObservable();
  
  constructor() { }
    
  showPreloader() {
    console.log("show");
    this.updateLoaderSource.next(true);
  }
  hidePreloader() {
    console.log("hide");
    this.updateLoaderSource.next(false);
  }
  

  
  
}
