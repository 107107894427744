import { DashboardComponent } from '../pages/home/dashboard/dashboard.component';
import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { CanDeactivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardGuard implements CanDeactivate<DashboardComponent>{


  constructor(
    private router: Router,
    private location: PlatformLocation
  ) { }

  canDeactivate(
    component: DashboardComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {

      this.location.onPopState((e: any) => {
        if(this.router.url.startsWith('/pages/home/dashboard')){
          this.location.forward();
        };
        console.log('pressed back!', this.router.url);
    });
    console.log('back!');
    return true;

  }



//  routerCanDeactivate() {
//    return confirm('Are you sure you want to leave this route');
//  }
}
