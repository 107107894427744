import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';


@Injectable()
export class ToastService {

  constructor(private _snackbar: MatSnackBar) { }
  private show(msg) {
    this._snackbar.open(msg, "", {
      duration: 5000
    });
  };
  success(msg){
    this.show(msg)
  };
  error(msg){
    this._snackbar.open(msg, "", {
      duration: 20000
    });
  };
  info(msg){
    this.show(msg)
  };
  warning(msg){
    this.show(msg)
  };

}
