import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileOtpComponent } from './mobile-otp.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

import {FormsModule} from "@angular/forms";
import { MaterialModule } from '../../common/material-module';

@NgModule({
  imports: [
    MaterialModule,
    FormsModule,
    CommonModule
  ],
  declarations: [MobileOtpComponent]
})
export class MobileOtpModule { }
