import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './registration.component';
import {FormsModule} from "@angular/forms";
import { MaterialModule } from '../../common/material-module';


@NgModule({
  imports: [
    MaterialModule,
    FormsModule,
    CommonModule,

  ],
  declarations: [RegistrationComponent]
})
export class RegistrationModule { }
