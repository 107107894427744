import { LoaderService } from './common/loader.service';
import { Component, OnDestroy } from '@angular/core';

import { ISubscription } from "rxjs/Subscription";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy{
  subscription : ISubscription;
  ngOnDestroy(): void {
    //console.log("App Component destroyed");
    this.subscription.unsubscribe();
  }
  title = 'app works!';
  constructor(private loaderService: LoaderService) {
      this.subscription = loaderService.updateLoader$.subscribe(data => {
        this.loaderStatus(data);
    });
  }
  appLoader = false;
  loaderStatus(status){
    console.log("helloooooooo", status)
    this.appLoader = status;
  }


}
