import { environment } from './../../environments/environment';
import { Injectable, Inject, ErrorHandler } from '@angular/core';
import {Response, Http,  Headers,  RequestOptions} from '@angular/http';
import { CommonService } from "./common.service";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";
import { Router } from '@angular/router';


@Injectable()
export class HttpClientService {
  authError: any;

  constructor(private http: Http, private commonService: CommonService,private router: Router) { };

  getThirdParty(url, param?: string, headers?: any): Observable<any> {
    if (typeof param === 'object') {
      param = this.commonService.stringParams(param);
    }
    if (param != null && param != "") {
      param = "?" + param;
    } else {
      param = "";
    }
    return this.http.get(url + param, this.jwt(headers)).map(res => { return res.json() || {}; }).catch((e)=> this.handleError(e));
  };
  get(url, param?: any, customHeader?: any): Observable<any> {
    if (typeof param === 'object') {
      param = this.commonService.stringParams(param);
    }
    if (param != null) {
      param = "?" + param;
    } else {
      param = "";
    }
    return this.http.get(environment.API_ENDPOINT + url + param, this.jwt(customHeader)).
    map(res => { return res.json() || {}; }).catch((e)=> this.handleError(e));
  };
  post(url, param?: any, customHeader?: any): any {
    return this.http.post(environment.API_ENDPOINT + url, param, this.jwt(customHeader)).map(res => res.json()).catch((e)=> this.handleError(e));
  };

  put(url, param?: any, customHeader?: any): any {
    return this.http.put(environment.API_ENDPOINT + url, param, this.jwt(customHeader)).map(res => res.json()).catch((e)=> this.handleError(e));
  };
  postFile(url, formData?: any, customHeader?: any): any {
    return this.http.post(environment.API_ENDPOINT + url, formData, this.jwt(customHeader)).map(res => res.json()).catch((e)=> this.handleError(e));
  };
  postWithParams(url, params?: any, customHeader?: any): any {
    return this.http.post(environment.API_ENDPOINT + url, null, this.jwt(customHeader, params)).map(res => res.json()).catch((e)=> this.handleError(e));
  }


  uploadFile(apiUrl: string, name: string, file: File): Observable<any> {
    let headers = new Headers();
    headers.append('Auth-Token', this.commonService.getToken());

    let options = new RequestOptions({ headers: headers });

    let formData: FormData = new FormData();
    formData.append(name, file, file.name);
    return this.http.post(`${environment.API_ENDPOINT}${apiUrl}`, formData, options).map(res => res.json()).catch((e)=> this.handleError(e));;
  }

  downloadFile(apiUrl: string, contentType): Observable<Object[]> {
    return Observable.create(observer => {

        let xhr = new XMLHttpRequest();
        xhr.open('GET', `${environment.API_ENDPOINT}/${apiUrl}`, true);
        xhr.setRequestHeader('Auth-Token',this.commonService.getToken());
        xhr.responseType = 'blob';

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                     console.log(xhr.response)

                    let blob = new Blob([xhr.response], { type:  contentType});
                    observer.next(blob);
                    observer.complete();
                } else {
                    observer.error(xhr.response);
                }
            }
        };
        xhr.send();

    });
  }
  private jwt(customHeaders?: any, params?: any): RequestOptions {

    let headers = new Headers();
    // let headers = new Headers({ 'Auth-Token': "ABCD" });


    if (customHeaders != null) {
      for (var k in customHeaders)
        headers.append(k, customHeaders[k]);
    }
    if (this.commonService.getToken() != null) {
      console.log(!headers.has(this.commonService.authToken))
      if (!headers.has(this.commonService.authToken))
        headers.append(this.commonService.authToken, this.commonService.getToken());
    }

    return new RequestOptions({ headers: headers, params: params })

  }


  addToOnlineOrder() {
    let headers: Headers = new Headers();
    headers.append("Authorization", "Basic " + btoa("dtr:dtr18Feb2015"));
    //headers.append("Content-Type", "multi-part/fo");
    let formdata = new FormData();
    formdata.append('user_id', this.commonService.getData().userId);
    formdata.append('rawhtml', 'sample html');
    formdata.append('order_url', window.location.href);
    formdata.append('store_id', '4');
    return this.http.post(environment.ONLINE_ORDER_ENDPOINT, formdata, { headers: headers })
  }

  private handleError(error: Response) {
    this.authError = error.json();
    console.log(this.authError.statusCode);
    if(this.authError.statusCode == "403"){
    this.commonService.removeAllStorage();
      try {
        eval("HTMLOUT.logout()");
      } catch (w) { }
      console.log(this);
      this.router.navigate(["/pages/mobileOtp"]);
    }
    return Observable.throw(error.json());
 }
}
