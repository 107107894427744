import { ToastService } from './../../common/toast.service';
import { CommonService } from './../../common/common.service';
import { ContactUsService } from './contact-us.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  message;
  subject;
  emailTo;
  loading = false;
  isRetailer;
  constructor(
    private _location:Location,
    private contactService: ContactUsService,
    private storage: CommonService,
    private toast: ToastService
  ) {
    this.isRetailer = this.storage.isRetailer();
  }

  ngOnInit() {
    this.emailTo = this.storage.getData().emailId;
  }

  sendMessage(f) {
    this.loading=true;
    this.contactService.sendFeedBack(this.message, this.subject).subscribe(
      (data)=> {
        if(data.responseStatus=='SUCCESS' && data.response) {
          f.reset();
          this.toast.success('Your message has been sent successfully!');
        } else {
          this.toast.error('Some problem occurred. Please try again');
        }
        this.loading=false;
      },
      (err) => {
          this.toast.error('Some problem occurred. Please try again');
          this.loading=false;
      }
    )
  }
  navigateBack() {
    this._location.back();
  }

}
