import { Injectable } from '@angular/core';
import {HttpClientService} from "../../common/http-client.service";
import {Observable} from "rxjs/Observable";

@Injectable()
export class MobileOtpService {

  constructor(private _http: HttpClientService) { }

  public generateOTP(params): Observable<any>{
    return this._http.get("otp/generateOTP",params);
  };
  public verifyOTP(params): Observable<any>{
    return this._http.get("otp/verifyOTP",params);
  };
  public registration(params): Observable<any>{
    return this._http.post("user",params);
  };

}
