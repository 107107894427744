import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {CommonService} from "../common/common.service";

@Injectable()
export class RetailerRegisterService implements CanActivate{


  constructor(private router: Router, private store: CommonService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.store.getStorage(this.store.loggedInStatus) == null || this.store.getStorage(this.store.loggedInStatus) == "undefined" || this.store.getStorage(this.store.loggedInStatus) == false) {
      this.router.navigate(['pages/mobileOtp']);
      return false;
    }

    return true;
  }

}
