import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {FormsModule} from "@angular/forms";

import { RouterModule } from "@angular/router";
import { SearchPipe, TimthumbPipe, ShipingChargesPipe, SelectedNodePipe, KeysPipe, RoundPipe } from './common-pipe.pipe';
import { InfiniteScrollerDirective } from './infinite-scroller.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ModalModule} from "ngx-bootstrap/modal";
import { DateFormatPipe, MomentModule, LocalePipe } from 'angular2-moment';
import { MaterialModule } from './material-module';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    InfiniteScrollModule,
    MomentModule,
    ModalModule.forRoot()
  ],
  declarations: [SearchPipe, InfiniteScrollerDirective, TimthumbPipe,ShipingChargesPipe, SelectedNodePipe, KeysPipe, RoundPipe],
  providers: [ShipingChargesPipe],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    InfiniteScrollerDirective,
    InfiniteScrollModule,
    SearchPipe,
    TimthumbPipe,
    ShipingChargesPipe,
    SelectedNodePipe,
    DateFormatPipe,
    LocalePipe,
    DatePipe,
    KeysPipe,
    RoundPipe
  ]
})
export class SharedModule { }
