import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CommonService} from "../../common/common.service";

@Component({
  selector: 'app-check-retailer-registration',
  templateUrl: './check-retailer-registration.component.html',
  styleUrls: ['./check-retailer-registration.component.css']
})
export class CheckRetailerRegistrationComponent implements OnInit {

  show = false;
  constructor(private common: CommonService, private _activatedRoute: ActivatedRoute, private _router:Router) { }

  ngOnInit() {
    console.log("logg",this.common.getStorage(this.common.checkRetailerRegistration))
    if(this.common.getStorage(this.common.loggedInStatus) == false){
      this._router.navigate(['/pages/mobileOtp']);
    } else if(this.common.getStorage(this.common.registered) != null &&  this.common.checkRetailer.indexOf(this.common.getStorage(this.common.registered)['userStatus']) != -1){
      this._router.navigate(['/pages/home/dashboard']);
    } else if(this.common.getStorage(this.common.registered) != null &&  ["notVerifiedRetailer"].indexOf(this.common.getStorage(this.common.registered)['userStatus']) != -1){
      this._router.navigate(['/pages/home/thankYou']);
    } else if(this.common.getStorage(this.common.checkRetailerRegistration) == 0){
      this._router.navigate(['/pages/home/dashboard']);
     } else {
       this.show = true;
    }

  }

  register(status){
    this.common.setStorage(this.common.checkRetailerRegistration, status);
    if(status){
      this._router.navigate(['/pages/home/retailerRegistration'])
    } else {
      this._router.navigate(['/pages/home/dashboard'])
    }
  }

}
