import { Component, OnInit } from '@angular/core';
import { ToastService } from "../../common/toast.service";
import { MobileOtpService } from "../mobile-otp/mobile-otp.service";
import { CommonService } from "../../common/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClientService } from '../../common/http-client.service'

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  user: any = {};
  states=[];
  cities = [];
  public loading: boolean = false;
  otherParams: any = {};
  constructor(private toast: ToastService,
  private _service: MobileOtpService,
  private httpService: HttpClientService,
  private common: CommonService,
  private _activatedRoute: ActivatedRoute,
  private _router: Router) {
        this.states =this.common.states;
  }

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe(
      params => {
        console.log('queryParams', params['email'])
        this.otherParams = params;
        this.user.emailId = params['email'];
        this.user.mobieNumber = params['phone'];
      }
    );
  }

  public registration() {
    this.loading = true;
    let temp = Object.assign(this.user, this.otherParams);
    this._service.registration(temp)
      .subscribe(data => {
        this.loading = false;
        if (data['responseStatus'].toLowerCase() == 'success') {
          this.toast.success(data['response']);
          this.common.setStorage(this.common.data, temp)
          this.common.setStorage(this.common.loggedInStatus, true);
          var token = this.common.getToken();
          console.log("token", token);
          this.httpService.get('user/detail/token', null, { 'Auth-Token': token })
            .subscribe(data => {
              if ('newAuthToken' in data.response)
                this.common.setStorage(this.common.newToken, data.response.newAuthToken);
              //code inserted for android purpose
              this.common.setStorage(this.common.registered, data.response);
              try {
                eval("HTMLOUT.authTokenChange('" + this.common.getToken() + "')");
              } catch (e) {
                console.log(e);
              }
             // console.log("newToken", this.common.getToken());
              this._router.navigate(['/pages/checkRegistration']);
            }, error => {
              this._router.navigate(['/pages/checkRegistration']);
            });

        } else {
          this.toast.error(data['response'])
        }
      }, error => {
        this.loading = false;
        if(error.json().statusCode == 400){
        this.toast.error(error.json().response.message)
        console.log("error",error.json())
        }
        else{
         console.log("error",error.json())
        this.toast.error("Something went wrong")
        }

     });

  };

    //For input type=number, it doesnt allow numeric characters post maxChars
  onKeyDown(event: any, maxChars) {
    if (event.target.value.length == maxChars && (event.keyCode >= 48 && event.keyCode <= 57)) {
      event.preventDefault();
    }
  }

  filterCities(val: string) {
    return val ? this.cities.filter(s => new RegExp(`^${val}`, 'gi').test(s))
      : this.cities;
  }



}
