import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckRetailerRegistrationComponent } from './check-retailer-registration.component';

import {FormsModule} from "@angular/forms";
import { SharedModule } from '../../common/shared.module';

@NgModule({
  imports: [
            SharedModule
  ],
  declarations: [CheckRetailerRegistrationComponent]
})
export class CheckRetailerRegistrationModule { }
