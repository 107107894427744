import { FormsModule} from '@angular/forms';
import { ContactUsService } from './contact-us.service';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from './contact-us.component';
import { MaterialModule } from '../../common/material-module';

@NgModule({
  imports: [
    CommonModule, MaterialModule, FormsModule
  ],
  declarations: [ContactUsComponent],
  providers: [ContactUsService]
})
export class ContactUsModule { }
