export class Constants {
  data: any = "data";
  shippingCharges = 0;
  token: any = "token";
  registered = "registered";
  checkRetailerRegistration = "cRR";
  authToken = "Auth-Token";
  loggedInStatus = "lgdinstts";
  startToken = "startToken";
  newToken = "updatedToken";
  storeUrl = "storeUrl";
  initPage = "/pages/checkRegistration";
  carts = "carts";
  pincode = "pc";
  androidOtp = "android.otpReceived";
  assetLink = "https://images.smartdukaan.com";
  selectedAddressId = 'adrsId';
  selfPickUp = 'slfPk';
  deliveryCharges = 'dlvryChrgs';
  myWalletUsed = "mWUsd";
  myWalletAmount = "mWAmnt";
  CODUsed = 'codUsd';
  paymentOptionUsed = 'pmntoptnusd';
  selectedPaymentOption = 'slctdPmntoptn';
  paymentId = 'pmntId';
  androidId = 'ndrdId';
  deviceId='viceId';
  checkRetailer = ["retailer", "fofo", "verifiedRetailer", "fofoAssociate"];
  checkFofo = ["fofo"];
  checkFofoAssociate = ["fofoAssociate"]
  policy = 'policy';
  timelineStatus="timelineStatus"
  storeAuthorizedemail = 'storeAuthorizedemail'
  //checkFofo = [];
  defaultAddress = 'defaultAddress';
  codStatus = 'codStatus'
  version = '1.5.5'
  rechargeObj = 'rechargeObj';
  rechargeoptName = 'rechargeoptName';
  holdDisable = 'holdDisable'
  states = [
    'Andaman & Nicobar Islands',
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chandigarh',
    'Chhattisgarh',
    'Dadra & Nagar Haveli',
    'Daman & Diu',
    'Delhi',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu & Kashmir',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Lakshdweep',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Orissa',
    'Pondicherry',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttrakhand',
    'West Bengal',
  ];
  opeartorTypes = [
    'PREPAID',
    'POSTPAID'
  ];
}
