import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {Constants} from "./app/common/constants";
import {HttpClientService} from "./app/common/http-client.service";
import {CommonService} from "./app/common/common.service";
import {Http} from "@angular/http";


if (environment.production) {
  enableProdMode();
}

var token = "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJwcm9maXRtYW5kaSIsImV4cCI6MTUwMjE3Mzc0NiwiaWF0IjoxNDk2OTg5NzQ2LCJ1c2VySWQiOjQ3LCJyb2xlTmFtZXMiOlsiVVNFUiIsIlJFVEFJTEVSIl19.R-79-ogO5qFGsGQgg4_QX4BvHBUb61YNw-oyMinNadY"
var cons = new Constants();
var common = new CommonService();
function loadDoc() {
  token = common.getToken();
  //console.log('token----->', common.getToken())
  var xhttp:XMLHttpRequest = new XMLHttpRequest();
  xhttp.onreadystatechange = function() {
    //console.log("ready state change has been called  " + xhttp.responseURL);
    if (this.readyState == 4 && this.status == 200) {
      try{
        var obj = JSON.parse(this.responseText);
        if(obj.statusCode == 200){
          if(obj.response.userStatus == "notRegistered"){
            common.setStorage(common.loggedInStatus, false);
          } else {
            common.setStorage(common.loggedInStatus, true);
            common.setStorage(common.defaultAddress, obj.response.address);
            common.setStorage(common.timelineStatus,  obj.response.timelineStatus);
            common.setStorage(common.storeUrl, obj.response.storeUrl);
            if(obj.response.userInfo) {
              common.setStorage(common.data, obj.response.userInfo);
            }
            if("newAuthToken" in obj.response){
              common.setStorage(common.newToken, obj.response["newAuthToken"]);
            }
          }
          common.setStorage(common.registered, obj.response);
        } else {
          common.setStorage(common.loggedInStatus, false);
        }
        platformBrowserDynamic().bootstrapModule(AppModule);
      } catch(e) {
        console.log("caught", e);
        console.log("catch",this.responseText)
      }
    };
  };
  xhttp.open("GET", environment.API_ENDPOINT+"user/detail/token", true);
  xhttp.setRequestHeader(cons.authToken,token);
  xhttp.send();
};
loadDoc();





