// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //API_ENDPOINT : "http://shop2020.in:8080/profitmandi-web/",
  //API_ENDPOINT: "http://localhost:8080/profitmandi-web/",
  API_ENDPOINT : "https://apptest.smartdukaan.com/apis/",
  //API_ENDPOINT: "https://app.smartdukaan.com/apis/",
  ONLINE_ORDER_ENDPOINT: "http://staging.profittill.com/orders/add",
  APP_URL: "https://pm.shop2020.in",
  STORE_APP_URL: "https://pm.shop2020.in"
};
