import { Observable } from 'rxjs/Observable';
import { CommonService } from './../../common/common.service';
import { HttpClientService } from './../../common/http-client.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ContactUsService {

  constructor(
    private common: CommonService,
    private http: HttpClientService
  ) {

  }

  sendFeedBack(message: string, subject: string):Observable<any> {
    return this.http.post('contact-us/feedback', { message: message, subject: subject });
  }

}
