import {Component, ModuleWithProviders, OnInit} from '@angular/core';

import {ToastService} from "../../common/toast.service";
import {ActivatedRoute, Router, RouterModule, Routes} from "@angular/router";
import {CommonService} from "../../common/common.service";
import {MobileOtpService} from "./mobile-otp.service";

@Component({
  selector: 'app-mobile-otp',
  templateUrl: './mobile-otp.component.html',
  styleUrls: ['./mobile-otp.component.css']
})
export class MobileOtpComponent implements OnInit {

  public verifyOtp:boolean = false;
  public user: any = {};
  public verify: any = {};
  public loading:boolean = false;
  public loadingOTP:boolean = false;
  constructor(private toast: ToastService, private _service: MobileOtpService, private common: CommonService, private _activatedRoute: ActivatedRoute, private _router:Router) {
    // _activatedRoute.queryParams.subscribe(
    //   params => {
    //     console.log('queryParams', params['email'])
    //     this.user.email = params['email'];
    //   }
    // );

    let obj = this.common.getData();
    console.log(obj);
    if(obj != null){
          this.user.email = obj['emailId'];
    }

  }


  ngOnInit() {
  }

  public login(){
    console.log('working');
    this.loading = true;
    this._service.generateOTP(this.common.stringParams(this.user))
      .subscribe(data => {
        this.loading = false;
        if(data['responseStatus'].toLowerCase() == 'success'){
          if(data['response']['result'] == true){
            this.verify.email = this.user.email;
            this.verify.phone = this.user.phone;
            this.verify.reference_id = String(data['response']['reference_id']);
            console.log(this.verify.reference_id);
            // this.verifyOtp = true;
            this.toast.success(data['response']['message'])
            this._router.navigate(['/pages/mobileVerify'],{ queryParams: this.verify });
            //{ queryParams: this.verify });
          } else {
            this.toast.error(data['response']['message'])

          }
        } else {
          this.toast.error("Server error")
        }
      }, error => {
        this.loading = false;
         if(error.statusCode == 400){
        this.toast.error(error.response.message)
        console.log("error",error.response.message);
         }
      else{
        console.log("error",error.json())
        this.toast.error("Something went wrong")
      }
      });

  };

}
