import {ContactUsModule} from './contact-us/contact-us.module';
import {FaqModule} from './faq/faq.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PagesComponent} from './pages.component';
import {MobileOtpModule} from "./mobile-otp/mobile-otp.module";
import {RouterModule} from "@angular/router";
import {MobileVerifyModule} from "./mobile-verify/mobile-verify.module";
import {MobileOtpService} from "./mobile-otp/mobile-otp.service";
import {RegistrationModule} from "./registration/registration.module";
import {NotfoundModule} from "./notfound/notfound.module";
import {CheckRetailerRegistrationModule} from "./check-retailer-registration/check-retailer-registration.module";
import {UpdateAppComponent} from './update-app/update-app.component';
import { NavigationService } from '../common/nav-service.service';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MobileOtpModule,
        MobileVerifyModule,
        RegistrationModule,
        CheckRetailerRegistrationModule,
        NotfoundModule,
        FaqModule,
        ContactUsModule
    ],
    declarations: [PagesComponent, UpdateAppComponent
    ],
    providers: [MobileOtpService,NavigationService]

})
export class PagesModule {
}
