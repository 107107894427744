import { UpdateAppComponent } from './pages/update-app/update-app.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import {RouterModule, Routes} from "@angular/router";
import {ModuleWithProviders} from "@angular/core";
import {PagesComponent} from "./pages/pages.component";
import {MobileOtpComponent} from "./pages/mobile-otp/mobile-otp.component";
import {MobileVerifyComponent} from "./pages/mobile-verify/mobile-verify.component";
import {RegistrationComponent} from "./pages/registration/registration.component";
import {HomeComponent} from "./pages/home/home.component";
import {NotfoundComponent} from "./pages/notfound/notfound.component";
import {CheckRetailerRegistrationComponent} from "./pages/check-retailer-registration/check-retailer-registration.component";
import {RetailerRegisterService} from "./guards/retailer-register.service";
import {Constants} from "./common/constants";
/**
 * Created by mohit on 07-05-2017.
 */
// const cons = new Constants();
const router:Routes  = [
  { path: 'skus/filter/:skus', redirectTo : '/pages/home/skus/:skus', pathMatch:'full'},
  { path: 'categories/linkdeal', redirectTo:'/pages/home/deals', pathMatch:'prefix'},
  { path: 'cashbacks/mine', redirectTo:'/pages/home/myWallet', pathMatch:'prefix'},
  { path: '', redirectTo: "/pages/checkRegistration", pathMatch: 'full'},
  {
    path: 'pages',
    component: PagesComponent,
    children: [
      {path: '', redirectTo: "/pages/checkRegistration", pathMatch: 'full'},
      {path: 'mobileOtp', component:MobileOtpComponent, data:{title:"Mobile"}},
      {path: 'faq', component:FaqComponent, data:{title:"FAQ"}},
      {path: 'contact-us', component:ContactUsComponent, data:{title:"Contact Us"}},
      {path: 'mobileVerify', component:MobileVerifyComponent, data:{title:"Mobile Verify"}},
      {path: 'registration', component:RegistrationComponent, data:{title:"Registration"}},
      {path: 'checkRegistration', component:CheckRetailerRegistrationComponent, data:{title:"Check Registration"}},
      {path: 'home', loadChildren: "app/pages/home/home.module#HomeModule", canActivate:[RetailerRegisterService], data:{title:"Home"}},
      {path:'updateApp',component:UpdateAppComponent,data:{title:"UpdateApp"}}
    ],
  },
  { path: '**', component: NotfoundComponent },
];


export const routing: ModuleWithProviders = RouterModule.forRoot(router);
