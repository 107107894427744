import {CommonService} from './common.service';
import { Pipe, PipeTransform } from '@angular/core';
import round from 'lodash-es/round';
@Pipe({ name: 'search', pure: false })
export class SearchPipe implements PipeTransform {
  transform(categories: any, searchText: any,column:any): any {
    if(searchText == null || searchText == "") return categories;
    return categories.filter((category) => {
      return (category[column] || "").toLowerCase().indexOf((searchText || "").toLowerCase()) > -1;
    })
  }
};


@Pipe({
  name: 'timthumb',
  pure: false
})
export class TimthumbPipe implements PipeTransform {
  transform(value: any, args?: string): any {
    if (value == null)
      return "";
    let height = args;
    return `https://images.smartdukaan.com/app/webroot/timthumb.php?src=${encodeURIComponent(value)}&h=${height}`;
  };
}

@Pipe({
  name: 'shipingChargesPipe',
  pure: false
})
export class ShipingChargesPipe implements PipeTransform {

  constructor(
    private common: CommonService
  ) {

  }
  transform(value: any, args?: any): any {
    if (value == null || !value.length)
      return 0;
    let sum = 0;
    for (let s of value) {
      try {
        sum += (s.sellingPrice) * (s.quantity);
      } catch (e) {
        sum = 0;
      }
    }
    if (sum >= 1000 || sum == 0) {
      return 0;
    } else {
      return this.common.shippingCharges;
    }
  }

}

@Pipe({
  name: 'deals',
  pure: false
})
export class SelectedNodePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value == null)
      return [];
    return value.filter(function (o, i) {
      return i == (args || 0);
    })
  }

}

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value, args:string[]) : any {
    let keys = [];
    for (let key in value) {
      keys.push({key: key, value: value[key]});
    }
    return keys;
  }
}
@Pipe({name: 'round'})
export class RoundPipe {
  transform (input:number, precision:number=0) {
    return round(input, precision);
  }
}
