import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {Http, HttpModule, RequestOptions, XHRBackend} from '@angular/http';
import 'hammerjs';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {PagesModule} from "./pages/pages.module";
import {CommonService} from "./common/common.service";
import {HttpClientService} from "./common/http-client.service";
import {ToastService} from "./common/toast.service";
import {routing} from "./app.routing";
import {CurrenySymbol} from './common/custom.directive';
import {InterceptedHttpService} from './common/intercepted-http.service';
import {LoaderService} from './common/loader.service';
import {RetailerRegisterService} from "./guards/retailer-register.service";
import {DashboardGuard} from './guards/gaurds';
import {MaterialModule} from './common/material-module';

export function httpFactory(xhrBackend: XHRBackend, requestOptions: RequestOptions): Http {
    return new InterceptedHttpService(xhrBackend, requestOptions);
}

@NgModule({
    declarations: [
        AppComponent,
        CurrenySymbol,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpModule,
        BrowserAnimationsModule,
        MaterialModule,
        PagesModule,
        routing,
    ],
    providers: [
        CommonService,
        HttpClientService,
        ToastService,
        RetailerRegisterService,
        DashboardGuard,
        {
            provide: Http,
            useFactory: httpFactory,
            deps: [XHRBackend, RequestOptions]
        },
        LoaderService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
