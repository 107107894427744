import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileVerifyComponent } from './mobile-verify.component';
import {FormsModule} from "@angular/forms";
import { MaterialModule } from '../../common/material-module';

@NgModule({
  imports: [
    MaterialModule,
    FormsModule,
    CommonModule
  ],
  declarations: [MobileVerifyComponent]
})
export class MobileVerifyModule { }
