import { CommonService } from './../../common/common.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  qas;
  hindiqas;
  punqas

english :boolean =true;
hindi:boolean =false;
punjabi :boolean =false;

  /*clickedQaSections;
  clickedQas;*/
  constructor(
    private _location: Location,
    private common: CommonService
  ) {

    this.qas = [{
      qas: [{
        q: 'What is SmartDukaan?',
        a: "SmartDukaan is India's first digital platform for mobiles and electronic appliances and has the relevant licences to establish a nationwide network of franchisee outlets. Whether you are an existing mobile retailer or want to open a new outlet, SmartDukaan offers an unique proposition to enable you to set up your business. We provide mobile phone and other electronic appliances from all reputed brands under one roof. In order to help you grow your sales, we align you with brand promoters. Our partnerships with different finance companies allows you to offer EMI services to your customers."
      },
      {
        q: "What is the name of the Company and where is it's Head Office?",
        a: "SmartDukaan has its Head Office in Noida. It is promoted by New Spice Solutions Pvt. Ltd. and is managed by experienced professionals from the Telecom Industry."
      },
      {
        q: "Where can I find SmartDukaan Stores?",
        a: "SmartDukaan has 150+ stores across Delhi/NCR, UP, Haryana and Punjab. Smart Dukaan plans to start its operation in other states shortly"
      },
      {
        q: "How much do I need to invest into SmartDukaan to start my own business?",
        a: "There is a Brand Fee of 2.5 lacs (+ GST). Thereafter, you will need to invest around 10 lacs in the business. You will be required to keep amount invested for at least 12 days in a month, failing which there may be cutbacks to the investment margin. Repeated default may lead to closing of the billing system."
      },
      {
        q: "Can SmartDukaan help me in selling from my outlet?",
        a: "SmartDukaan can only help you by providing the right stock, aligning you to brand promoters, and mapping finance services. Retail customer sales will be your responsibility."
      },
      {
        q: "How much retail space is required for becoming a franchisee of SmartDukaan?",
        a: "You need approximately 150-200 sq ft area in an area with high footfall to open your franchisee outlet."
      },
      {
        q: "I am running another business on my GST. Can I use the same GST for SmartDukaan franchisee business?",
        a: "It is always advisable to have a new GST and a new business name for the franchisee outlet. This will speed things up in terms of getting brand approvals, aligning brand promoters and in finance mapping."
      },
      {
        q: "By when can my finance mapping be done?",
        a: "We will try to get your mapping aligned ASAP, but a lot will depend on finance options being available in your city where the franchise outlet is planned, your past finance ratings, your CIBIL etc."
      },
      {
        q:"Is all stock available all the time?",
        a:"No brand ensures that all of their stock will always be readily available. However, we try and maintain sufficient stock levels for you to remain operational at all times. So, there will hardly be a 'Stock Out' situation."
      },

      {
        q:"Where will I get the stock from?",
        a:"You will get the stock form the nearest SmartDukaan warehouse. SmartDukaan will take care of the stock supply. All you need to do is place a timely order for the things you need, so that it can be replenished on time."
      },


      {
        q:"Who will build my store? I want to get involved when my outlet is being designed.",
        a:"You will be required to do the basic civil work, painting, flooring and ceiling work. Smart Dukaan will do the rest. We will take measurements of your shop based on which a 2D and 3D version of the design will be sent to you for approval. After your approval, the shop will be finalised by a professional agency."
      },


      {
        q:"Who will arrange for the brand promoters? Who will pay them?",
        a:"SmartDukaan will try its best to align Brand Promoters. It becomes easier if you can start selling at least 15-20 smartphones of any brand. In such cases, the respective Brands also come forward to assign the promoters for your outlet. The salary of promoters will be borne by the brands."
      },


      {
        q:"Does SmartDukaan help me in adversting? Will I get customised pamphlets for SmartDukaan?",
        a:"SmartDukaan would already be doing digital marketing for you. Even before your outlet opens, SmartDukaan starts its digital campaign for you via Facebook, Instagram, Twitter etc. We don’t do Pamphlet Marketing as we rely heavily on digital marketing."
      },


      {
        q:"In what other ways can SmartDukaan support me?",
        a:"Carrying on negotiations with brands for better offers, introducing new products, arranging finance options, arranging brand promoters, giving branded carry bags, business cards, staff training, toll free helpline number, stock planning, demo zones, dummies, demo availability etc. are some of the ways Smart Dukaan can additionally support you. This is apart from a professional dashboard and a state of the art mobile app, from which you can manage your complete business."
      },
      {
        q:"Will you force any material to be purchased by me?",
        a:"SmartDukaan will never force any franchisee to buy anything. You can buy whatever brand you want, whichever model you want and selecting the SKU you want to sell is also your choice. There will be never be a forced billing."
      },
      {
        q:"Do you provide credit?",
        a:"Yes, you can avail SD credit facility. It is based on your CIBIL and other financial history. Credit is as per Banking system specially designed for SmartDukaan partners."
      },
      {
        q:"Can I sell any non SmartDukaan stock from my outlet?",
        a:"Strictly NO. You cannot sell outside stock from the day SmartDukaan branding is done at your outlet. Purchasing from any other vendor shall either lead to immediate termination of the franchise store or heavy penalty will be imposed."
      },
      {
        q:"After getting associated with SmartDukaan, can I engage in wholeselling of stock?",
        a:"This is strictly NO. The whole concept of SmartDukaan is all about retailing. You have to work within the boundaries of retail sale. In case you are found to be involved in wholesale activity, you may be penalised and in extreme cases your franchisee may be cancelled."
      },
      {
        q:"Can I get all brands from 1st day?",
        a:"Smart Dukaan's 70-80% market share is with Vivo, Oppo and Samsung. Your first billing will at least be worth 2.5-3 lacs from each of these 3 brands. You will start getting stocks from other brands once you have sold products worth at least 3-4 lacs from these 3 Brands. The first billing will be based on pre-decided plan program which will have all running models. You must also purchase accessories to make your product portfolio complete as a SmartDukaan franchisee."
      },
      {
        q:"Do you provide accessories?",
        a:"Yes, we have a full range of accessories of all the running brands."
      },
      {
        q:"Can I sell any brand of my choice?",
        a:"Yes you can. But Oppo, Vivo, Samsung must be sold and no one brand's share can exceed 50% of your total business."
      },
      {
        q:"How much can I earn by being SmartDukaan franchisee?",
        a:"Your earnings will depend upon the amount of business you carry out. The more customers you sell to, higher are the chances to be on a high margin slab."
      },
      {
        q:"Can I sell phones in bulk to RETAILERS?",
        a:"No, we strictly work in the B2C model. So you must sell products to end customers only."
      },
      {
        q:"I have many other businesses. Can someone else sit in SmartDukaan franchisee outlet?",
        a:"In our books, you are the franchisee owner. It's your choice to put someone else physically at the outlet. But your involvement in the business will make a huge difference. So we suggest that you take full interest and ownership of your store."
      },
      {
        q:"Can I get engaged with other business activities?",
        a:"Yes, you can. But putting in your best on a SmartDukaan Franchisee will give you higher results"
      },
        {
        q:"There is a SmartDukaan in my area. Can I open another franchisee outlet near this outlet?",
        a:"As a policy, we protect the business interest of our franchisees. It would be advisable that you select a location which is at least 2-3 Kms from an existing franchisee outlet. It will protect your business too."
      },
      {
        q:"Can I punch a bill for 3 phones in one single invoice?",
        a:"No, you can bill only 1 phone in a single invoice."
      },
      {
        q:"Can I transfer my wallet money to my bank account?",
        a:"Unless your account is live with SmartDukaan, you cannot transfer your wallet balance to an account."
      },
      {
        q:"Can I recharge from my SmartDukaan app?",
        a:"We recommend doing it with your dashboard and not from the app which allows to check the recharge status real-time."
      },
      {
        q:"How do I edit incorrect customer details in the bill?",
        a:"You have to write to us at care@smartdukaan.com or inform your service manager for such changes, mentioning the correct customer details."
      },
      {
        q:"How do I cancel an invoice if the customer doesn’t want to purchase?",
        a:"You have to raise the request on an email to care@smartdukaan.com or inform your service manager, specifying the reason behind the cancellation."
      },
      {
        q:"Can I use demo phones personally?",
        a:"Brands allot demo phones for displaying at the counter. You cannot sell/activate the phones for 3 months."
      },
      {
        q:"What if a phone is reported faulty within 2-3 days of sale?",
        a:"Customer has to visit nearest service centre to get the DOA letter. We can replace the phone from the brand if the DOA letter is issued."
      },
      {
        q:"What do I do when the courier box received seems to be damaged?",
        a:"Immediately report to your BDM / RBM and also report the issue on the POD slip."
      },
      {
        q:"My store address is different from the address mentioned in GST. How to change the registered address?",
        a:"You have to submit the request for adding your business place on the second page of GST. After the addition of the business place, we can change your registered address."
      }


      ]
    }

    ];
    for (let qaSection of this.qas) {
      qaSection.clicked = false;
      for (let qa of qaSection) {
        qa.clicked = false;
      }
    }

    this.hindiqas = [{
      qas: [{
        q:"स्मार्टदुकान क्या है?",
        a:"स्मार्टदुकान भारत का पहला मोबाइल और इलेक्ट्रॉनिक्स रिटेल चेन है जो डिजिटल प्लेटफॉर्म के माध्यम से देश भर में फैले मोबाइल और इलेक्ट्रॉनिक्स रिटेलर्स को एक सूत्र में जोड़कर उन्हें आगे बढ़ाने का काम कर रहा है। चाहे आप एक मौजूदा मोबाइल रिटेलर हैं, या एक नया आउटलेट खोलना चाहते हैं, स्मार्टदुकान आपको आगे बढ़ाने में पूर्णतः सक्षम है। हमारे प्लेटफार्म पर सभी प्रमुख मोबाइल और इलेक्ट्रॉनिक्स ब्रांड उपलब्ध हैं। हमारे पास फाइनेंस कंपनियों के साथ टाईअप है ताकि आप अपने ग्राहकों को EMI और ईज़ी फाइनेंस जैसी सेवाएं दे सकें। हम अपने पार्टनर्स को मार्केटिंग और टेक्नोलॉजी का बेहतरीन सपोर्ट देते हैं जिस से आप अपना व्यवसाय बढ़ा सकते हैं।"
      },

      {
        q:"स्मार्टदुकान का हेडऑफ़िस कहाँ है? कंपनी का नाम क्या है?",
        a:"स्मार्टदुकान अपने नोएडा हेडऑफिस के माध्यम से देशभर से जुड़ा है। हमारी कंपनी का नाम न्यू स्पाइस सॉल्यूशंस प्राइवेट लिमिटेड है। "
      },

      {
        q:"स्मार्टदुकान स्टोर कहाँ हैं?",
        a:"वर्तमानकाल में स्मार्टदुकान के तक़रीबन 150+ स्टोर, दिल्ली / NCR, यूपी, हरियाणा और पंजाब में हैं। अन्य राज्यों में भी जल्द ही स्मार्टदुकान के पार्टनर्स खुलने जा रहे हैं।"
      },

      {
        q:"स्मार्टदुकान से जुड़ने के लिए कितना निवेश आवश्यक है?",
        a:"आपको व्यवसाय में लगभग 10 लाख रूपए निवेश करने की आवश्यकता होती  है। आपको महीने में कम से कम 12 दिनों के लिए इस न्यूनतम निवेश को बरकरार रखना पड़ता है। उस का पालन ना करने पर निवेश मार्जिन में कटौती हो सकती है एवं डिफ़ॉल्ट होने से बिलिंग सिस्टम बंद हो सकता है। हम ब्रांड फीस के रूप में रुपये 2.5 लाख + GST चार्ज करते हैं जिमसे अधिकतम पैसा आपकी दूकान को बनाने और प्रमोट करने में खर्च किया जाता है। "
      },
      {
        q:"क्या स्मार्टदुकान मेरे आउटलेट से बिक्री में मेरी मदद कर सकता है?",
        a:"स्मार्टदुकान केवल आपको सही स्टॉक प्रदान करके, स्मार्टदुकान ब्रांड के बारे में जागरूकता बढ़ा कर, ब्रांड प्रमोटरों को ट्रेनिंग देकर और फाइनेंस ऑफर्स के द्वारा सेल्स बढ़ाने में आपकी सहायता कर सकता है। रिटेल बिक्री आपकी जिम्मेदारी है। "
      },
      {
        q:"स्मार्टदुकान की फ्रेंचाइजी बनने के लिए कितने खुदरा (रिटेल) स्थान की आवश्यकता है?",
        a:"अपना  फ्रैंचाइज़ी आउटलेट खोलने के लिए आपको एक अच्छे वॉक-इन लोकेशन में लगभग 150-200 स्क्वायर फुट एरिया की आवश्यकता है।"
      },
      {
        q:"मैं अपने GST पर एक और व्यवसाय चला रहा हूं। क्या मैं फ्रेंचाइजी व्यवसाय के लिए उस ही GST का उपयोग कर सकता हूं?",
        a:"एक नए फ्रेंचाइजी आउटलेट के लिए एक नई फर्म का नाम होना बेहतर है। इससे ब्रांड अप्रूव होने, ब्रांड प्रमोटर का प्रबंध करने और फाइनेंस मैपिंग करवाने के मामले में आसानी होती है और तेज़ गति से काम हो जाता है।"
      },
      {
        q:"मेरी वित्त की मैपिंग कब से की जा सकती है?",
        a:"हम आपके फ्रेंचाइजी आउटलेट को जल्द से जल्द मैप करने की कोशिश करेंगे लेकिन इस काम की तेजी आपके CIBIL रेटिंग, पिछले वित्त रेटिंग, आपके फ्रेंचाइजी आउटलेट के स्थान आदि के साथ आपके एरिया की वित्त कंपनियों पर निर्भर करेगा।"
      },
      {
        q:"क्या सभी स्टॉक हर समय उपलब्ध है?",
        a:"हर समय हरेक स्टॉक बनाए रखना संभव नहीं है। ऐसा कोई ब्रांड नहीं है, जो यह सुनिश्चित कर सके। हालाँकि, हम हर समय ज़्यादा डिमांड वाले मॉडल की उपलब्धता बनाए रखने की कोशिश करते हैं जिससे आपको कभी भी कठिनाई / स्टॉकआउट स्थितियों का सामना नहीं करना पड़े।"
      },
      {
        q:"मुझे स्टॉक कहां से मिलेगा?",
        a:"आपको स्मार्टदुकान के निकटतम गोदाम से  मिलेगा। आपको स्टॉक सप्लाई के बारे में चिंता करने की जरूरत नहीं है। स्मार्टदुकान इसका ध्यान रखेगा। आपसे बस एक छोटा सा निवेदन है की जिस स्टॉक की आपको ज़्यादा ज़रुरत पड़ती है कृपया उसका समय पर आर्डर दें ताकि आपको  स्टॉक आउट स्थितियों का सामना नहीं करना पड़े और बिक्री के अवसर को खोना न पड़े।  "
      },
      {
        q:"दुकान कौन बनाएगा? जब मेरा आउटलेट डिजाइन किया जा रहा है तो मैं इसमें शामिल होना चाहता हूं।",
        a:"आपको बेसिक काम जैसे सिविल वर्क,  पेंटिंग, फर्श, AC और छत का काम करना होगा। बाकी सब कुछ स्मार्टदुकान आपके लिए करेगा। हम पहले आपकी दुकान का माप लेंगे। इसके आधार पर 2 डी और 3 डी दूकान का डिज़ाइन और प्लान बनाएंगे जो अप्रूवल के लिए भेजा जाएगा। आपके अप्रूवल के बाद, दुकान को एक पेशेवर एजेंसी द्वारा अंतिम रूप दिया जाएगा।"
      },
      {
        q:"ब्रांड प्रमोटरों की व्यवस्था कौन करेगा? उनके वेतन का भुगतान कौन करेगा?",
        a:"स्मार्टदुकान आपको ब्रांड प्रमोटर देने का पूरा प्रयास करता है पर यह आपके आउटलेट के प्रदर्शन और क्षमता पर निर्भर करता है । यदि आप किसी भी ब्रांड के कम से कम 15-20 स्मार्टफोन बेचना शुरू करते हैं, तो हमारे प्रयास को यह आसान कर देता है फिर ब्रांड भी रुचि लेते हैं और आपके आउटलेट के लिए प्रमोटरों को जल्दी से असाइन करते हैं। प्रमोटर का वेतन सीधे ब्रांड द्वारा दिया जाएगा।"
      },

      {
        q:"क्या स्मार्टदुकान मुझे विज्ञापन में मदद करता है? क्या मुझे स्मार्टदुकान के लिए अनुकूलित पैम्फलेट्स मिलेंगे?",
        a:"स्मार्टदुकान मार्केटिंग के नए साधन डिजिटल मार्केटिंग का इस्तेमाल करता है। आपका आउटलेट खुलने से पहले ही, स्मार्टदुकान  फेसबुक, इंस्टाग्राम, गूगल  आदि के माध्यम से डिजिटल अभियान शुरू कर देता है। पैम्फलेट्स बाँटना अब एक बेहतर तरीका नहीं है क्योंकि आज की दुनिया में डिजिटल मार्केटिंग पुराने विज्ञापन साधनों से काफी आगे है। समय समय पर  हम नए ऑफर लाते हैं और आपको उचित मार्केटिंग मटेरियल भी देते हैं। "
      },
      {
        q:"स्मार्टदुकान द्वारा और क्या समर्थन दिया जाएगा?",
        a:"ब्रांडों के साथ बैकएंड डील्स , नए प्रोडक्ट्स को पेश करना, फाइनेंस  विकल्पों की व्यवस्था करना, ब्रांड प्रमोटरों की व्यवस्था करना, ब्रांडेड कैरी बैग, बिज़नेस कार्ड, स्टाफ प्रशिक्षण, टोल-फ़्री हेल्पलाइन नंबर, स्टॉक प्लानिंग, डेमो ज़ोन, डमी, डेमो प्रोडक्ट्स, इत्यादि लाभ स्मार्टदुकान के साथ जुड़कर आपको प्राप्त होंगे। हमारे सरल डैशबोर्ड और एक अत्याधुनिक मोबाइल ऐप के द्वारा आप अपने संपूर्ण व्यवसाय का संचालन कर सकते हैं।"
      },
      {
        q:"क्या आप मेरे द्वारा खरीदी जाने वाली किसी भी सामग्री के लिए मुझे बाध्य करेंगे?",
        a:"आप जो भी ब्रांड, मॉडल, SKU चाहे वो खरीद सकते हैं।  स्मार्टदुकान कभी भी किसी भी फ्रेंचाइजी को कोई बिलिंग करने के लिए बाध्य नहीं करता और इस बात पर आप निश्चिन्त रह सकते हैं।"
      },
      {
        q:"क्या आप क्रेडिट प्रदान करते हैं?",
        a:"हां, आप एसडी क्रेडिट सुविधा का लाभ उठा सकते हैं। यह आपके CIBIL और अन्य आर्थिक इतिहास पर निर्भर है। यह क्रेडिट बैंकिंग प्रणाली के अनुसार है जिसे विशेष रूप से स्मार्टदुकान पार्टनर्स के लिए डिज़ाइन किया गया है।"
      },
      {
        q:"क्या मैं अपने आउटलेट से कोई भी गैर स्मार्टदुकान स्टॉक बेच सकता हूँ ?",
        a:"बिलकुल नहीं। जिस दिन स्मार्टदुकान ब्रांडिंग आपके आउटलेट पर की जाती है, उस दिन से आप बाहर के स्टॉक को नहीं बेच सकते। किसी अन्य विक्रेता से खरीद करने पर या तो फ्रैंचाइज़ी स्टोर तत्काल रद्द हो जायेगा या भारी जुर्माना लगाया जाएगा।"
      },
      {
        q:"स्मार्टदुकान के साथ जुड़ने के बाद, क्या मैं स्टॉक की थोक (होलसेल) बिक्री कर सकता हूं?",
        a:"नहीं। स्मार्टदुकन का पूरा कॉन्सेप्ट रिटेल बिक्री पर आधारित है। आपको रिटेल बिक्री की सीमाओं के भीतर काम करना होगा। थोक (बल्क)  बिक्री करते पाए जाने पर आपकी फ्रेंचाइजी को रद्द किया जा सकता है और आप पर जुरमाना लगाया जा सकता है।"
      },
      {
        q:"क्या मुझे पहले दिन से सभी ब्रांड मिल सकते हैं?",
        a:"आज 70-80% मार्किट शेयर  विवो, ओप्पो और सैमसंग के पास है और आपको इन ब्रांड्स के मॉडल्स मिक्स करके दिए जायेंगे । आपकी पहली बिलिंग प्रत्येक ब्रांड की कम से कम 2.5 - 3 लाख होगी। इन 3 ब्रांडों के स्टॉक का कम से कम 3-4 लाख बेचने पर हम अन्य ब्रांड देने शुरू करेंगे। पहली बिलिंग एक पूर्व-तय प्लान पर आधारित होगी जिसमें सभी रनिंग मॉडल होंगे। स्मार्टदुकान फ्रेंचाइजी के रूप में अपने प्रोडक्ट पोर्टफोलियो को पूरा करने के लिए आपको एक्सेसरीज भी ख़रीदनी होंगी।"
      },

      {
        q:"क्या आप एक्सेसरीज प्रदान करते हैं?",
        a:"हां, हमारे पास रनिंग ब्रांड के एक्सेसरीज की पूरी श्रृंखला है।"
      },
      {
        q:"क्या मैं अपनी पसंद का कोई ब्रांड बेच सकता हूं?",
        a:"हाँ, आप बेच सकते हैं। लेकिन ओप्पो, वीवो, सैमसंग को जरूर बेचा जाना चाहिए और किसी भी ब्रांड का हिस्सा आपके कुल व्यवसाय का 50% से अधिक नहीं हो सकता है।"
      },
      {
        q:"स्मार्टदुकान फ्रेंचाइजी से मैं कितना कमा सकता हूं?",
        a:"आपकी कमाई आपके द्वारा किए जाने वाले व्यवसाय की मात्रा पर निर्भर करेगी। आप जितने अधिक ग्राहकों को प्रोडक्ट्स  बेचते हैं, उतनी अधिक आप की कमाई होने की संभावना है।"
      },
      {
        q:"क्या मैं थोक (बल्क) में खुदरा (रिटेल) विक्रेताओं को फोन बेच सकता हूं?",
        a:"नहीं, हम B2C  मॉडल में काम कर रहे हैं। इसलिए आपको केवल ग्राहकों को ही बेचने की अनुमति है | "
      },
      {
        q:"मेरे पास कई अन्य व्यवसाय हैं। क्या कोई और स्मार्टदुकान फ्रेंचाइजी आउटलेट में बैठ सकता है?",
        a:"स्मार्टदुकान के मुख्य मालिक आप हैं। यदि आप चाहें तो अपने कर्मचारी के द्वारा आउटलेट को चला सकते है,  लेकिन व्यवसाय में आपकी भागीदारी से बहुत फर्क पड़ेगा। इसलिए हमारा सुझाव यह है कि आप अपने स्टोर को चलाने में  पूरी रुचि लें।   "
      },

      {
        q:"क्या मैं अन्य व्यावसायिक गतिविधियों से जुड़ सकता हूं?",
        a:"हाँ । लेकिन स्मार्टदुकान फ्रेंचाइजी व्यवसाय पर पूरा ध्यान देने से आपको इस व्यवसाय से बेहतर परिणाम मिलेंगे।"
      },

      {
        q:"मेरे एरिया में एक स्मार्टदुकान पहले से मौजूद है। क्या मैं इस आउटलेट के पास अपना खुद का एक और फ्रेंचाइजी आउटलेट खोल सकता हूं?",
        a:"हमारी पॉलिसी के अनुसार हम हर फ्रेंचाइजी के व्यावसायिक हितों की रक्षा करते हैं। इसलिए यह उचित होगा कि आप ऐसे स्थान का चयन करें जो एक मौजूदा फ्रेंचाइजी आउटलेट से कम से कम 2-3 किलोमीटर की दूरी पर हो | यह आपके व्यवसाय के लिए फायदेमंद होगा।"
      },

      {
        q:"क्या मैं सिंगल इनवॉइस में 3 फोन का बिल पंच कर सकता हूं?",
        a:"नहीं, आप एक चालान में केवल 1 ही फोन बिल कर सकते हैं।"
      },

      {
        q:"क्या मैं अपने वॉलेट का पैसा अपने बैंक खाते में ट्रांसफर कर सकता हूं?",
        a:"जब तक आपका खाता स्मार्टदुकान के साथ नहीं है, आप खाते में अपना वॉलेट कॅश ट्रांसफर नहीं कर सकते।"
      },
      {
        q:"क्या मैं अपने स्मार्टदुकान ऐप से रिचार्ज कर सकता हूं?",
        a:"ऍप के बदले हम इसे आपके डैशबोर्ड द्वारा करने की सलाह देते हैं, जहां आप रियल टाइम रिचार्ज स्थिति की जांच कर सकते हैं।"
      },
      {
        q:"यदि बिल गलत दर्ज किया गया है तो ग्राहक विवरण को कैसे बदलें ?",
        a:"इस तरह के बदलावों के लिए आपको care@smartdukaan.com पर मेल कर सूचित करना होगा और सही ग्राहक विवरणों का उल्लेख करना होगा। इसके अतिरिक्त आप अपने एरिया के सर्विस मैनेजर से भी संपर्क कर सकते हैं।"
      },
      {
        q:"यदि ग्राहक खरीदारी नहीं करना चाहता है तो चालान कैसे रद्द करें?",
        a:"रद्द करने का कारण बताते हुए आपको मेल आई डी care@smartdukaan.com पर अनुरोध करना होगा । इसके अतिरिक्त आप अपने एरिया के सर्विस मैनेजर से भी संपर्क कर सकते हैं।"
      },
      {
        q:"क्या मैं व्यक्तिगत रूप से डेमो फोन का उपयोग कर सकता हूं?",
        a:"काउंटर पर प्रदर्शित करने के लिए ब्रांड आपको डेमो फोन देते है। आप 3 महीने तक इन फोन को बेच / सक्रिय नहीं कर सकते।"
      },
      {
        q:"बेचने के 2-3 दिनों के भीतर फोन खराब होने पर क्या करें?",
        a:"ग्राहक को DOA पत्र प्राप्त करने के लिए नज़दीकी सेवा केंद्र पर जाना होगा। DOA लेटर जारी होने पर हम फोन को ब्रांड से बदल सकते हैं।"
      },
      {
        q:"एक ख़राब कूरियर बॉक्स मिलने पर क्या करें?",
        a:"तुरंत अपने बीडीएम / आरबीएम को सूचित करें और पीओडी स्लिप पर भी समस्या रिपोर्ट करें।"
      },
      {
        q:"मेरे स्टोर का पता GST में बताए गए पते से अलग है। रजिस्टर्ड पते को कैसे बदलें?",
        a:"आपको GST के दूसरे पृष्ठ पर अपने व्यवसाय के स्थान को जोड़ने के लिए अनुरोध करना होगा। व्यावसायिक स्थान के जुड़ने के बाद, हम आपके रजिस्टर्ड पते को बदल सकते हैं"
      },



      ]
    }]

    for (let qaSection of this.hindiqas) {
      qaSection.clicked = false;
      for (let qa of qaSection) {
        qa.clicked = false;
      }
    }

    this.punqas = [{
      qas: [{
            q:"ਸਮਾਰਟ ਦੁਕਾਨ ਕੀ ਹੈ?",
            a:"ਸਮਾਰਟ ਦੁਕਾਨ ਭਾਰਤ ਦਾ ਪਹਿਲਾ ਮੋਬਾਈਲ ਅਤੇ ਇਲੈਕਟ੍ਰੌਨਿਕ ਦੀ ਰਿਟੇਲ  ਚੇਨ ਹੈ, ਜੋ ਡਿਜੀਟਲ ਪਲੇਟਫਾਰਮ ਰਾਹੀਂ ਦੇਸ਼ ਭਰ ਵਿਚ ਫੈਲੇ ਮੋਬਾਈਲ ਅਤੇ ਇਲੈਕਟ੍ਰਾਨਿਕਸ ਰਿਟੇਲ ਵਿਕਰੇਤਾਵਾਂ ਨੂੰ ਆਪਣੇ ਨਾਲ ਜੋੜ ਕੇ ਉਹਨਾਂ ਨੂੰ  ਅੱਗੇ ਵਧਾਉਣ ਲਈ ਕੰਮ ਕਰ ਰਿਹਾ ਹੈ । ਭਾਵੇਂ ਤੁਸੀਂ ਮੌਜੂਦਾ ਮੋਬਾਈਲ ਰਿਟੇਲਰ ਹੋ, ਜਾਂ ਨਵੀਂ ਦੁਕਾਨ ਖੋਲ੍ਹਣਾ ਚਾਹੰਦੇ ਹੋ, ਸਮਾਰਟ ਦੁਕਾਨ ਤੁਹਾਨੂੰ ਅੱਗੇ ਵਧਾਉਣ ਦੇ ਲਈ ਪੂਰੀ ਤਰ੍ਹਾਂ ਸਮਰੱਥ ਹੈ। ਸਾਡੇ ਪਲੇਟਫਾਰਮ ਉੱਤੇ ਸਾਰੇ ਮੁਖ ਮੋਬਾਈਲ ਅਤੇ ਇਲੈਕਟ੍ਰੋਨਿਕਸ ਬ੍ਰਾਂਡ ਮੌਜੂਦ ਹਨ ।ਸਾਡਾ ਸਾਰੀ ਫਾਈਨਾਂਸ ਕੰਪਨੀਆਂ ਨਾਲ ਮੇਲ-ਜੋਲ ਹੈ ਤਾਂ ਜੋ ਤੁਸੀਂ ਆਪਣੇ ਗ੍ਰਾਹਕਾਂ ਨੂੰ ਈ।ਐੱਮ।ਆਈ। ਅਤੇ ਆਸਾਨੀ ਨਾਲ ਫਾਈਨਾਂਸ ਸੇਵਾਵਾਂ ਦੀ ਪੇਸ਼ਕਸ਼ ਕਰ ਸਕੋ। ਅਸੀਂ ਅਪਣੇ ਨਾਲ ਜੁੜੇ ਸਹਿਭਾਗੀਆਂ ਨੂੰ ਮਾਰਕੀਟਿੰਗ ਅਤੇ ਤਕਨੀਕੀ ਦੀ ਸਭ ਤੋਂ ਵਧੀਆ ਸਹਾਇਤਾ ਦਿੰਦੇ ਹਾਂ ਜਿਸ ਨਾਲ ਤੁਸੀਂ ਆਪਣਾ ਕਾਰੋਬਾਰ ਵਧਾ ਸਕਦੇ ਹੋ।"
      },
      {
            q:"ਸਮਾਰਟ ਦੁਕਾਨ ਦਾ ਮੁੱਖ ਦਫਤਰ ਕਿੱਥੇ ਹੈ? ਕੰਪਨੀ ਦਾ ਨਾਮ ਕੀ ਹੈ?",
            a:"ਸਮਾਰਟ ਦੁਕਾਨ  ਆਪਣੀ ਨੋਏਡਾ ਹੈੱਡ ਆਫਿਸ ਦੇ ਰਾਹੀਂ ਪੂਰੇ ਦੇਸ਼ ਵਿੱਚ ਜੁੜਿਆ ਹੋਇਆ ਹੈ। ਸਾਡੀ ਕੰਪਨੀ ਦਾ ਨਾਂ ਨਿਊ ਸਪਾਈਸ ਸਲਉਸ਼ਨ ਪ੍ਰਾਈਵੇਟ ਲਿਮਿਟੇਡ ਹੈ।"
      },
      {
            q:"ਸਮਾਰਟ ਦੁਕਾਨ ਸਟੋਰ ਕਿੱਥੇ ਹਨ?",
            a:"ਇਸ ਮੌਜੂਦਾ ਸਮੇਂ ਵਿੱਚ ਸਮਾਰਟ ਦੁਕਾਨ ਦੇ ਲਗਭਗ 150+ ਸਟੋਰ ਹਨ,ਜੋ ਕਿ ਦਿੱਲੀ / ਐਨਸੀਆਰ, ਯੂਪੀ, ਹਰਿਆਣਾ ਅਤੇ ਪੰਜਾਬ ਵਿੱਚ ਹਨ। ਅਤੇ ਹੋਰ ਸੁਬਿਆਂ ਵਿਚ ਵੀ ਸਮਾਰਟ ਦੁਕਾਨ ਦੇ ਸਹਿਭਾਗੀ ਛੇਤੀ ਹੀ ਖੋਲ੍ਹਣ ਜਾ ਰਹੇ ਹਾਂ।"
      },
      {
            q:"ਸਮਾਰਟ ਦੁਕਾਨ ਨਾਲ ਜੁੜਨ ਲਈ ਕਿੰਨੇ ਨਿਵੇਸ਼ ਦੀ ਲੋੜ ਹੈ?",
            a:"ਤੁਹਾਨੂੰ ਕਾਰੋਬਾਰ ਵਿਚ ਲਗਭਗ 10 ਲੱਖ ਰੁਪਏ ਲਗਾਉਣ ਦੀ ਜ਼ਰੂਰਤ ਹੈ। ਤੁਹਾਨੂੰ ਇੱਕ ਮਹੀਨੇ ਵਿੱਚ ਘੱਟੋ ਘੱਟ 12 ਦਿਨਾਂ ਲਈ ਇਸ ਘੱਟੋ ਘੱਟ ਨਿਵੇਸ਼ ਨੂੰ ਯਕੀਨੀ ਬਣਾਈ ਰੱਖਣਾ ਹੈ। ਇਸਦਾ ਪਾਲਣ ਕਰਨ ਵਿੱਚ ਅਸਫਲ ਹੋਣ ਦੇ ਨਤੀਜੇ ਵਜੋਂ ਨਿਵੇਸ਼ ਦੇ ਮਾਰਜਿਨ ਵਿੱਚ ਕਮੀ ਆ ਸਕਦੀ ਹੈ ਅਤੇ ਬਿਲਿੰਗ ਦੇ ਬੰਦ ਹੋਣ ਦਾ ਨਤੀਜਾ ਹੋ ਸਕਦਾ ਹੈ। ਅਸੀਂ ਬ੍ਰਾਂਡ ਫੀਸ ਦੇ ਤੌਰ ਤੇ 2।5 ਲੱਖ ਰੁਪਏ + ਜੀਐਸਟੀ ਲੈਂਦੇ ਹਾਂ। ਜ਼ਿਆਦਾਤਰ ਪੈਸਾ ਤੁਹਾਡੀ ਦੁਕਾਨ ਬਣਾਉਣ ਅਤੇ ਇਸ ਨੂੰ ਵਧਾਵਾ ਦੇਣ ਲਈ ਖਰਚਿਆ ਜਾਂਦਾ ਹੈ।"
      },
      {
            q:"ਕੀ ਸਮਾਰਟ ਦੁਕਾਨ ਮੇਰੇ ਆਉਟਲੈੱਟ ਦੀ ਵਿਕਰੀ ਵਿਚ ਮੇਰੀ ਮਦਦ ਕਰ ਸਕਦੀ ਹੈ?",
            a:"ਸਮਾਰਟ ਦੁਕਾਨ ਤੁਹਾਨੂੰ ਸਹੀ ਸਟਾਕ ਦੇ ਕੇ, ਸਮਾਰਟ ਦੁਕਾਨ ਬ੍ਰਾਂਡ ਦੇ ਬਾਰੇ ਤੁਹਾਡੀ ਜਾਗਰੂਕਤਾ ਵਧਾਂਦਾ ਹੈ ਅਤੇ ਬ੍ਰਾਂਡ ਪ੍ਰਮੋਟਰਾਂ ਅਤੇ  ਫਾਈਨਾਂਸ ਸੇਵਾਵਾਂ ਦੀ ਪੇਸ਼ਕਸ਼ਾਂ ਪ੍ਰਤੀ ਜਾਗਰੂਕਤਾ ਪੈਦਾ ਕਰਕੇ ਵਿਕਰੀ ਵਧਾਉਣ ਵਿਚ ਤੁਹਾਡੀ ਮਦਦ ਕਰਦਾ ਹੈ। ਰਿਟੇਲ ਬਿਕਰੀ ਤੁਹਾਡੀ ਜ਼ਿੰਮੇਵਾਰੀ ਹੈ।"
      },
      {
              q:"ਸਮਾਰਟ ਦੁਕਾਨ ਦੀ ਫਰੈਂਚਾਈਜ਼ੀ ਲੈਣ ਦੇ ਲਈ ਦੁਕਾਨ ਵਿਚ ਕਿੰਨੀ ਥਾਂ ਦੀ ਲੋੜ ਹੈ?",
              a:"ਆਪਣਾ ਫਰੈਂਚਾਇਜ਼ੀ ਆਉਟਲੈਟ ਖੋਲ੍ਹਣ ਲਈ, ਤੁਹਾਨੂੰ ਇਕ ਚੰਗੀ ਵਾਕ-ਇਨ ਸਥਿਤੀ ਵਿਚ ਲਗਭਗ 150-200 ਵਰਗ ਫੁੱਟ ਥਾਂ ਦੀ ਲੋੜ ਹੈ।"
      },
      {
        q:"ਮੈਂ ਆਪਣੇ ਜੀਐਸਟੀ 'ਤੇ ਇਕ ਹੋਰ ਕਾਰੋਬਾਰ ਚਲਾ ਰਿਹਾ ਹਾਂ। ਕੀ ਮੈਂ ਉਸੀ ਜੀਐਸਟੀ ਦੀ ਵਰਤੋਂ ਫਰੈਂਚਾਈਜ਼ੀ ਕਾਰੋਬਾਰ ਲਈ ਕਰ ਸਕਦਾ ਹਾਂ?",
        a:"ਨਵੇਂ ਫਰੈਂਚਾਈਜ਼ ਆਉਟਲੈੱਟ ਦੇ ਲਈ ਇੱਕ ਨਵੀਂ ਫਰਮ ਦਾ ਨਾਮ ਦੇਣਾ ਬਿਹਤਰ ਹੈ।ਇਸ ਨਾਲ ਬ੍ਰਾਂਡ ਦੀ ਮਨਜ਼ੂਰੀ ਪ੍ਰਾਪਤ ਕਰਨਾ, ਬ੍ਰਾਂਡ ਪ੍ਰਮੋਟਰਾਂ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰਨਾ ਅਤੇ ਫਾਈਨਾਂਸ ਮੈਪਿੰਗ ਕਰਵਾਉਣਾ ਅਤੇ ਤੇਜ਼ੀ ਨਾਲ ਕੰਮ ਕਰਨਾ ਸੌਖਾ ਬਣਾਉਂਦਾ ਹੈ।"
      },
      {
        q:"ਮੇਰੇ ਫਾਈਨਾਂਸ ਦੀ ਮੈਪਿੰਗ ਕਿੰਨੀ ਦੇਰ ਵਿਚ ਹੋ ਸਕਦੀ ਹੈ?",
        a:"ਅਸੀਂ ਜਿੰਨੀ ਜਲਦੀ ਹੋ ਸਕੇ ਤੁਹਾਡੇ ਫਰੈਂਚਾਇਜ਼ੀ ਆਊਟਲੈੱਟ ਨੂੰ ਛੇਤੀ ਤੋਂ ਛੇਤੀ ਫਾਈਨਾਂਸ ਸੇਵਾਵਾਂ ਦੇ ਨਾਲ ਜੋੜਣ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰਾਂਗੇ ਪਰ ਇਸ ਕੰਮ ਦੀ ਗਤੀ ਤੁਹਾਡੇ ਖੇਤਰ ਦੀਆਂ ਫਾਈਨਾਂਸ ਕੰਪਨੀਆਂ ਦੇ ਨਾਲ ਤੁਹਾਡੀ ਸਿਬਿਲ ਰੇਟਿੰਗ ਅਤੇ, ਤੁਹਾਡੇ ਫ੍ਰੈਂਚਾਇਜ਼ੀ ਆਉਟਲੈਟ ਦੀ ਥਾਂ ਅਤੇ ਉੱਥੇ ਮੌਜੂਦਾ  ਫਾਇਨਾਨਸ ਸੇਵਾਵਾਂ ਉਤੇ  ਨਿਰਭਰ ਕਰੇਗੀ।"
      },
      {
        q:"ਕੀ ਸਾਰਾ ਸਟਾਕ ਹਰ ਸਮੇਂ ਉਪਲਬਧ ਹੈ?",
        a:"ਹਰ ਸਮੇਂ ਹਰ ਸਟਾਕ ਨੂੰ ਬਣਾਈ ਰੱਖਣਾ ਸੰਭਵ ਨਹੀਂ ਹੈ। ਅਜਿਹਾ ਕੋਈ ਬ੍ਰਾਂਡ ਨਹੀਂ ਹੈ ਜੋ ਇਸ ਨੂੰ ਯਕੀਨੀ ਬਣਾ ਸਕੇ। ਹਾਲਾਂਕਿ, ਅਸੀਂ ਹਰ ਸਮੇਂ ਉੱਚ ਮੰਗ ਵਾਲੇ ਮਾਡਲਾਂ ਦੀ ਉਪਲਬਧਤਾ ਨੂੰ ਬਣਾਈ ਰੱਖਣ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰਦੇ ਹਾਂ ਤਾਂ ਜੋ ਤੁਹਾਨੂੰ ਕਦੇ ਮੁਸ਼ਕਲਾਂ ਦਾ ਸਾਹਮਣਾ ਨਾ ਕਰਨਾ ਪਵੇ।"
      },
      {
        q:"ਮੈਨੂੰ ਸਟਾਕ ਕਿੱਥੋਂ ਮਿਲ ਸਕਦਾ ਹੈ?",
        a:"ਤੁਸੀਂ ਇਸਨੂੰ ਸਮਾਰਟ ਦੁਕਾਨ  ਦੇ ਨਜ਼ਦੀਕੀ ਗੋਦਾਮ ਤੋਂ ਪ੍ਰਾਪਤ ਕਰ ਸਕਦੇ ਹੋ ,ਤੁਹਾਨੂੰ ਸਟਾਕ ਸਪਲਾਈ ਦੇ ਬਾਰੇ ਚਿੰਤਾ ਕਰਨ ਦੀ ਲੋੜ ਨਹੀਂ ਹੈ।ਸਮਾਰਟ ਦੁਕਾਨ ਇਸ ਦਾ ਧਿਆਨ ਰੱਖੇਗੀ। ਤੁਹਾਡੇ ਤੋਂ ਸਿਰਫ ਇੱਕ ਛੋਟੀ ਜਿਹੀ ਬੇਨਤੀ ਹੈ , ਕਿਰਪਾ ਕਰਕੇ ਸਮੇਂ ਤੇ ਸਟਾਕ ਆਰਡਰ ਕਰੋ ਜਿਸਦੀ ਤੁਹਾਨੂੰ ਵਧੇਰੀ ਜ਼ਰੂਰਤ ਹੈ ਤਾਂ ਜੋ ਤੁਹਾਨੂੰ ਸਟਾਕ ਨਾ ਹੋਣ ਦੀ  ਦੀਆਂ ਸਥਿਤੀਆਂ ਦਾ ਸਾਹਮਣਾ ਕਰਨਾ ਪਵੇ ਅਤੇ ਵਿਕਰੀ ਦਾ ਮੌਕਾ ਨਾ ਗੁਆਓ।"
      },
      {
        q:"ਦੁਕਾਨ ਕੌਣ ਬਣਾਏਗਾ? ਮੈਂ ਸ਼ਾਮਲ ਹੋਣਾ ਚਾਹੁੰਦਾ ਹਾਂ ਜਦੋਂ ਮੇਰਾ ਆਉਟਲੈਟ ਤਿਆਰ ਕੀਤਾ ਜਾਂਦਾ ਹੈ।",
        a:"ਤੁਹਾਨੂੰ ਬੁਨਿਆਦੀ ਕੰਮ ਕਰਨਾ ਪਏਗਾ ਜਿਵੇਂ ਸਿਵਲ ਵਰਕ, ਪੇਂਟਿੰਗ, ਫੁਰਨੀਸ਼ਿੰਗ, ਏ।ਸੀ। ਅਤੇ ਛੱਤ ਦਾ ਕੰਮ ਸ਼ਾਮਿਲ ਹੈ, ਹੋਰ ਸਭ ਕੁਝ ਸਮਾਰਟ ਦੁਕਾਨ ਤੁਹਾਡੇ ਲਈ ਕਰੇਗਾ। ਅਸੀਂ ਪਹਿਲਾਂ ਤੁਹਾਡੀ ਦੁਕਾਨ ਨੂੰ ਮਾਪਾਂਗੇ। ਇਸ ਦੇ ਅਧਾਰ 'ਤੇ, 2 ਡੀ ਅਤੇ 3 ਡੀ ਦੁਕਾਨਾਂ ਦਾ  ਡਿਜ਼ਾਈਨ ਅਤੇ ਯੋਜਨਾ ਤਿਆਰ ਕਰਾਂਗੇ  ਜੋ ਮਨਜ਼ੂਰੀ ਲਈ ਭੇਜੀਆਂ ਜਾਣਗੀਆਂ। ਤੁਹਾਡੀ ਮਨਜ਼ੂਰੀ ਤੋਂ ਬਾਅਦ, ਇੱਕ ਪੇਸ਼ੇਵਰ ਏਜੰਸੀ ਦੁਆਰਾ ਦੁਕਾਨ ਨੂੰ ਅੰਤਿਮ ਰੂਪ ਦਿੱਤਾ ਜਾਵੇਗਾ।"
      },
      {
        q:"ਬ੍ਰਾਂਡ ਪ੍ਰਮੋਟਰਾਂ ਲਈ ਕੌਣ ਪ੍ਰਬੰਧ ਕਰੇਗਾ? ਉਨ੍ਹਾਂ ਦੀ ਤਨਖਾਹ ਕੌਣ ਦੇਵੇਗਾ?",
        a:"ਸਮਾਰਟ ਦੁਕਾਨ ਤੁਹਾਨੂੰ ਇਕ ਬ੍ਰਾਂਡ ਪ੍ਰਮੋਟਰ ਦੇਣ ਲਈ ਵਧੀਆ ਕੋਸ਼ਿਸ਼ ਕਰਦਾ ਹੈ ਪਰ ਇਹ ਤੁਹਾਡੇ ਆਉਟਲੈੱਟ ਦੀ ਕਾਰਗੁਜ਼ਾਰੀ ਅਤੇ ਸਮਰੱਥਾ 'ਤੇ ਨਿਰਭਰ ਕਰਦਾ ਹੈ। ਜੇ ਤੁਸੀਂ ਕਿਸੇ ਵੀ ਬ੍ਰਾਂਡ ਦੇ ਘੱਟੋ ਘੱਟ 15-20 ਸਮਾਰਟਫੋਨਾਂ ਨੂੰ ਵੇਚਣਾ ਸ਼ੁਰੂ ਕਰਦੇ ਹੋ, ਤਾਂ ਸਾਡੀ ਕੋਸ਼ਿਸ਼ ਨੂੰ ਸੌਖਾ  ਬਣਾ ਦਿੰਦੀ ਹੈ ਤਾਂ ਬ੍ਰਾਂਡ ਵੀ ਦਿਲਚਸਪੀ ਲੈਂਦੇ ਹਨ ਅਤੇ ਜਲਦੀ ਹੀ ਤੁਹਾਡੇ ਆਉਟਲੈੱਟ ਨੂੰ ਪ੍ਰਮੋਟਰ ਲਗਾਉਂਦੇ ਹਨ। ਪ੍ਰਮੋਟਰ ਦੀ ਤਨਖਾਹ ਸਿੱਧੇ ਬ੍ਰਾਂਡ ਦੁਆਰਾ ਅਦਾ ਕੀਤੀ ਜਾਂਦੀ ਹੈ।"
      },
      {
        q:"ਕੀ ਸਮਾਰਟ ਦੁਕਾਨ ਇਸ਼ਤਿਹਾਰਬਾਜ਼ੀ ਵਿਚ ਮੇਰੀ ਮਦਦ ਕਰਦਾ ਹੈ? ਕੀ ਸਮਾਰਟ ਦੁਕਾਨ ਮੇਰੇ ਲਈ ਅਨੁਕੂਲਿਤ ਪਮਪਹਲੇਟ ਦੇਵੇਗਾ?",
        a:"ਸਮਾਰਟ ਦੁਕਾਨ ਮਾਰਕੀਟਿੰਗ ਦੇ ਨਵੇਂ  ਸਾਧਨਾਂ ਨਾਲ ਡਿਜਿਟਲ  ਮਾਰਕੀਟਿੰਗ ਦੀ ਵਰਤੋਂ ਕਰਦਾ ਹੈ। ਤੁਹਾਡੇ ਆਉਟਲੈਟ ਖੁੱਲ੍ਹਣ ਤੋਂ ਪਹਿਲਾਂ ਹੀ, ਸਮਾਰਟ ਦੁਕਾਨ ਫੇਸਬੁੱਕ, ਇੰਸਟਾਗ੍ਰਾਮ, ਗੂਗਲ, ​​ਆਦਿ ਦੁਆਰਾ ਡਿਜੀਟਲ ਮਾਰਕਿਟਿੰਗ ਦੀ ਸ਼ੁਰੂਆਤ ਕਰ ਦਿੰਦਾ ਹੈ । ਪੈਂਫਲੈਟਾਂ ਨੂੰ ਸਾਂਝਾ ਕਰਨਾ ਹੁਣ ਵਧੀਆ ਤਰੀਕਾ ਨਹੀਂ ਹੈ ਕਿਉਂਕਿ  ਅੱਜਕੱਲ ਦੇ  ਸਮੇਂ ਵਿੱਚ ਡਿਜੀਟਲ ਮਾਰਕੀਟਿੰਗ ਪੁਰਾਣੇ ਵਿਗਿਆਪਨ ਸਾਧਨਾਂ ਤੋਂ ਕਿਤੇ ਅੱਗੇ ਹੈ। ਸਮੇਂ ਸਮੇਂ ਤੇ ਅਸੀਂ ਨਵੀਆਂ ਪੇਸ਼ਕਸ਼ਾਂ ਲਿਆਉਂਦੇ ਹਾਂ ਅਤੇ ਤੁਹਾਨੂੰ ਉਚਿੱਤ ਮਾਰਕੀਟਿੰਗ ਸਮੱਗਰੀ ਵੀ ਦਿੰਦੇ ਹਾਂ।"
      },
      {
        q:"ਸਮਾਰਟ ਦੁਕਾਨ ਵੱਲੋ ਹੋਰ ਕੀ ਸਹਾਇਤਾ ਕੀਤੀ ਜਾਏਗੀ?",
        a:"ਸਮਾਰਟ ਦੁਕਾਨ ਦੀ ਬੈਕਐਂਡ ਟੀਮ ਸਾਰੇ ਬ੍ਰਾਂਡਾਂ ਨਾਲ ਗੱਲਬਾਤ ਕਰਦੀ ਹੈ, ਨਵੇਂ ਉਤਪਾਦਾਂ ਦੀ ਸ਼ੁਰੂਆਤ ਕਰਦੀ ਹੈ,ਫਾਈਨਾਂਸ ਸੇਵਾਵਾਂ ਦਾ ਪ੍ਰਬੰਧ ਕਰਦੀ ਹੈ, ਬ੍ਰਾਂਡ ਪ੍ਰਮੋਟਰਾਂ ਦਾ ਪ੍ਰਬੰਧ ਕਰਦੀ ਹੈ,ਬ੍ਰਾਂਡੇਡ  ਕੈਰੀ ਬੈਗ, ਵੀਸੀਟਿੰਗ ਕਾਰਡ, ਸਟਾਫ ਦੀ ਟ੍ਰੇਨਿੰਗ, ਟੋਲ-ਫ੍ਰੀ  ਹੈਲਪਲਾਈਨ ਨੰਬਰ, ਸਟਾਕ ਯੋਜਨਾਬੰਦੀ, ਡੈਮੋ ਜ਼ੋਨ, ਡਮੀ, ਡੈਮੋ ਉਤਪਾਦ, ਆਦਿ ਦਾ ਪ੍ਰਬੰਧ ਕਰਦੀ ਹੈ। ਤੁਸੀਂ ਸਮਾਰਟ ਦੁਕਾਨ ਨਾਲ ਜੁੜ ਕੇ ਸਾਡੇ ਸੁਖਾਲੇ ਡੈਸ਼ਬੋਰਡ ਅਤੇ ਇੱਕ ਅਤਿ-ਆਧੁਨਿਕ ਮੋਬਾਈਲ ਐਪ ਦੇ ਨਾਲ ਆਪਣਾ ਸਾਰਾ ਕਾਰੋਬਾਰ ਕਰ ਸਕਦੇ ਹੋ।"
      },
      {
        q:"ਕੀ ਤੁਸੀਂ ਮੈਨੂੰ ਕੁਝ ਵੀ ਖਰੀਦਣ ਲਈ ਮਜਬੂਰ ਕਰੋਗੇ?",
        a:"ਤੁਸੀਂ ਜੋ ਵੀ ਬ੍ਰਾਂਡ, ਮਾਡਲ, ਐਸਕਿਯੂ ਚਾਹੁੰਦੇ ਹੋ ਖਰੀਦ ਸਕਦੇ ਹੋ।  ਕਦੇ ਵੀ ਕਿਸੇ ਫ੍ਰੈਂਚਾਈਜ਼ੀ ਨੂੰ ਕੋਈ ਬਿਲਿੰਗ ਕਰਨ ਲਈ ਮਜਬੂਰ ਨਹੀਂ ਕਿੱਤਾ ਜਾਂਦਾ ਅਤੇ ਤੁਸੀਂ ਇਸ ਬਾਰੇ ਯਕੀਨ ਕਰ ਸਕਦੇ ਹੋ।"
      },
      {
        q:"ਕੀ ਤੁਸੀਂ ਕ੍ਰੈਡਿਟ ਪੇਸ਼ ਕਰਦੇ ਹੋ?",
        a:"ਹਾਂ, ਤੁਸੀਂ ਐਸ ਡੀ ਕ੍ਰੈਡਿਟ ਸਹੂਲਤ ਲੈ ਸਕਦੇ ਹੋ। ਇਹ ਤੁਹਾਡੇ ਸਿਬਿਲ  ਅਤੇ ਹੋਰ ਆਰਥਿਕ ਇਤਿਹਾਸ ਤੇ ਨਿਰਭਰ ਕਰਦਾ ਹੈ। ਇਹ ਕ੍ਰੈਡਿਟ ਬੈਂਕਿੰਗ ਪ੍ਰਣਾਲੀ ਦੇ ਅਨੁਸਾਰ ਹੈ ਜੋ ਵਿਸ਼ੇਸ਼ ਤੌਰ 'ਤੇ ਸਮਾਰਟ ਦੁਕਾਨ ਪਾਰਟਨਰਾਂ ਲਈ ਤਿਆਰ ਕੀਤਾ ਗਿਆ ਹੈ।"
      },
      {
        q:"ਕੀ ਮੈਂ ਆਪਣੇ ਆਉਟਲੈੱਟ ਤੋਂ ਕੋਈ ਵੀ ਗੈਰ-ਸਮਾਰਟ ਦੁਕਾਨ ਮਾਲ ਵੇਚ ਸਕਦਾ ਹਾਂ?",
        a:"ਹੋ ਨਹੀਂ ਸਕਦਾ। ਜਿਸ ਦਿਨ ਤੁਹਾਡੇ ਆਉਟਲੈੱਟ 'ਤੇ ਸਮਾਰਟ ਦੁਕਾਨ ਵਲੋਂ ਬ੍ਰਾਂਡਿੰਗ ਕੀਤੀ ਜਾਂਦੀ ਹੈ ਉਸ ਦਿਨ ਤੋਂ ਤੁਸੀਂ ਬਾਹਰ ਦਾ ਸਟਾਕ ਨਹੀਂ ਵੇਚ ਸਕਦੇ। ਕਿਸੇ ਹੋਰ ਵਿਕਰੇਤਾ ਤੋਂ ਖਰੀਦਣ ਦੇ ਨਤੀਜੇ ਵਜੋਂ ਜਾਂ ਤਾਂ ਫਰੈਂਚਾਈਜ਼ ਸਟੋਰ ਤੁਰੰਤ ਰੱਦ ਕਰ ਦਿੱਤਾ ਜਾਂਦਾ ਹੈ ਜਾਂ ਭਾਰੀ ਜੁਰਮਾਨਾ ਲੱਗ ਸਕਦਾ ਹੈ।"
      },
      {
        q:"ਕੀ ਮੈਂ ਸਮਾਰਟ ਦੁਕਾਨ ਵਿਚ ਸ਼ਾਮਲ ਹੋਣ ਤੋਂ ਬਾਅਦ ਸਟਾਕ ਦੀ ਹੋਲਸੇਲ ਕਰ ਸਕਦਾ ਹਾਂ?",
        a:"ਨਹੀਂ ਸਮਾਰਟ ਦੁਕਾਨ ਦੀ ਪੂਰੀ ਧਾਰਨਾ ਰਿਟੇਲ ਵਿਕਰੀ 'ਤੇ ਅਧਾਰਤ ਹੈ। ਤੁਹਾਨੂੰ ਰਿਟੇਲ ਵਿਕਰੀ ਦੀ ਸੀਮਾ ਦੇ ਅੰਦਰ ਹੀ ਕੰਮ ਕਰਨਾ ਪਏਗਾ। ਜੇ ਤੁਸੀਂ ਹੋਲਸੇਲ ਵਿੱਚ ਵੇਚਦੇ ਪਾਏ ਜਾਂਦੇ ਹੋ ਤਾਂ ਤੁਹਾਡੀ ਫਰੈਂਚਾਇਸੀ ਨੂੰ ਰੱਦ ਕੀਤਾ ਜਾ ਸਕਦਾ ਹੈ ਅਤੇ ਜੁਰਮਾਨਾ ਲੱਗ ਸਕਦਾ ਹੈ।"
      },
      {
        q:"ਕੀ ਮੈਂ ਪਹਿਲੇ ਦਿਨ ਤੋਂ ਸਾਰੇ ਬ੍ਰਾਂਡ ਪ੍ਰਾਪਤ ਕਰ ਸਕਦਾ ਹਾਂ?",
        a:"ਅੱਜ, 70-80% ਮਾਰਕੀਟ ਸ਼ੇਅਰ ਵੀਵੋ, ਓਪੋ ਅਤੇ ਸੈਮਸੰਗ ਦੇ ਨਾਲ ਹੈ ਅਤੇ ਤੁਹਾਨੂੰ ਇਹ ਬ੍ਰਾਂਡ ਮਿਲਾ ਕੇ ਮਾਡਲ ਦਿੱਤੇ ਜਾਣਗੇ। ਤੁਹਾਡੀ ਪਹਿਲੀ ਬਿਲਿੰਗ ਹਰੇਕ ਬ੍ਰਾਂਡ ਦੀ ਘੱਟੋ ਘੱਟ 2।5 ਤੋਂ  3 ਲੱਖ ਹੋਵੇਗੀ। ਇਨ੍ਹਾਂ 3 ਬ੍ਰਾਂਡਾਂ ਦਾ ਘੱਟੋ ਘੱਟ 3-4 ਲੱਖ ਦਾ ਸਟਾਕ ਵੇਚਣ ਤੋਂ ਬਾਅਦ, ਅਸੀਂ ਹੋਰ ਬ੍ਰਾਂਡ ਦੇਣਾ ਸ਼ੁਰੂ ਕਰਾਂਗੇ। ਪਹਿਲੀ ਬਿਲਿੰਗ ਇਕ ਪਹਿਲਾਂ ਤੋਂ ਨਿਰਧਾਰਤ ਯੋਜਨਾ 'ਤੇ ਅਧਾਰਤ ਹੋਵੇਗੀ ਜਿਸ ਵਿਚ ਸਾਰੇ ਚੱਲ ਰਹੇ ਮਾਡਲ ਹੋਣਗੇ। ਤੁਹਾਨੂੰ  ਫਰੈਂਚਾਇਜ਼ੀ ਦੇ ਤੌਰ ਤੇ ਆਪਣੇ ਉਤਪਾਦ ਪੋਰਟਫੋਲੀਓ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ ਅਸਸੇਰੀਜ਼ ਵੀ ਖਰੀਦਣੀ ਪਏਗੀ।"
      },
      {
        q:"ਕੀ ਤੁਸੀਂ ਅਸਸੇਰੀਜ਼ ਪ੍ਰਦਾਨ ਕਰਦੇ ਹੋ?",
        a:"ਹਾਂ,ਸਾਡੇ ਕੋਲ ਚਲ ਰਹੇ ਬਰਾਂਡ ਦੀ ਅਸਸੇਰੀਜ਼ ਦੀਆਂ ਸਾਰਿਆਂ ਚੀਜ਼ਾਂ ਹਨ।"
      },
      {
        q:"ਕੀ ਮੈਂ ਉਹ ਬ੍ਰਾਂਡ ਵੇਚ ਸਕਦਾ ਹਾਂ ਜੋ ਮੈਂ ਪਸੰਦ ਕਰਾਂ?",
        a:"ਹਾਂ, ਤੁਸੀਂ ਵੇਚ ਸਕਦੇ ਹੋ। ਪਰ ਓਪੋ, ਵੀਵੋ, ਸੈਮਸੰਗ ਨੂੰ ਜਰੂਰ ਵੇਚਿਆ ਜਾਣਾ ਚਾਹੀਦਾ ਹੈ ਅਤੇ ਕਿਸੇ ਵੀ ਬ੍ਰਾਂਡ ਦਾ ਹਿੱਸਾ ਤੁਹਾਡੇ ਕੁਲ ਕਾਰੋਬਾਰ ਦੇ 50% ਤੋਂ ਵੱਧ ਨਹੀਂ ਹੋ ਸਕਦਾ।"
      },
      {
        q:"ਮੈਂ ਸਮਾਰਟ ਦੁਕਾਨ ਫਰੈਂਚਾਇਜ਼ੀ ਤੋਂ ਕਿੰਨਾ ਕਮਾ ਸਕਦਾ ਹਾਂ?",
        a:"ਤੁਹਾਡੀ ਕਮਾਈ ਕਾਰੋਬਾਰ ਦੀ ਮਾਤਰਾ 'ਤੇ ਨਿਰਭਰ ਕਰੇਗੀ। ਜਿੰਨੇ ਜਿੰਨੇ ਜ਼ਿਆਦਾ ਤੁਸੀਂ ਗ੍ਰਾਹਕਾਂ ਨੂੰ ਸਮਾਨ ਵੇਚਦੇ ਹੋ, ਓਨੇ ਹੀ ਤੁਹਾਡੇ ਕਮਾਉਣ ਦੀ ਸੰਭਾਵਨਾ ਜ਼ਿਆਦਾ ਹੋਵੇਗੀ।"
      },
        {
        q:"ਕੀ ਮੈਂ ਹੋਲਸੇਲ ਵਿੱਚ ਦੁਕਾਨਦਾਰਾਂ ਨੂੰ ਫੋਨ ਵੇਚ ਸਕਦਾ ਹਾਂ?",
        a:"ਨਹੀਂ, ਅਸੀਂ ਬੀ 2 ਸੀ ਮਾਡਲ ਵਿੱਚ ਕੰਮ ਕਰ ਰਹੇ ਹਾਂ। ਇਸ ਲਈ ਤੁਹਾਨੂੰ ਸਿਰਫ ਗਾਹਕਾਂ ਨੂੰ ਵੇਚਣ ਦੀ ਆਗਿਆ ਹੈ।"
      },
        {
        q:"ਮੇਰੇ ਕੋਲ ਹੋਰ ਬਹੁਤ ਕਾਰੋਬਾਰ ਹਨ। ਕੀ ਕੋਈ ਹੋਰ ਸਮਾਰਟ ਦੁਕਾਨ ਫ੍ਰੈਂਚਾਈਜ਼ ਆਉਟਲੈੱਟ ਵਿਚ ਬੈਠ ਸਕਦਾ ਹੈ?",
        a:"ਤੁਸੀਂ ਸਮਾਰਟ ਦੁਕਾਨ ਦੇ ਮੁੱਖ ਮਾਲਕ ਹੋ। ਜੇ ਤੁਸੀਂ ਚਾਹੁੰਦੇ ਹੋ, ਤਾਂ ਤੁਸੀਂ ਆਪਣੇ ਕਰਮਚਾਰੀ ਦੁਆਰਾ ਆਉਟਲੈਟ ਚਲਾ ਸਕਦੇ ਹੋ, ਪਰ ਕਾਰੋਬਾਰ ਵਿਚ ਤੁਹਾਡੀ ਭਾਗੀਦਾਰੀ  ਨਾਲ ਬਹੁਤ ਫਰਕ ਹੋਏਗਾ। ਇਸ ਲਈ, ਸਾਡਾ ਸੁਝਾਅ ਇਹ ਹੈ ਕਿ ਤੁਸੀਂ ਆਪਣਾ ਸਟੋਰ ਚਲਾਉਣ ਵਿਚ ਪੂਰੀ ਦਿਲਚਸਪੀ ਲਓ।"
      },
      {
        q:"ਕੀ ਮੈਂ ਹੋਰ ਕਾਰੋਬਾਰੀ ਗਤੀਵਿਧੀਆਂ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋ ਸਕਦਾ ਹਾਂ?",
        a:"ਹਾਂ। ਪਰ ਤੁਹਾਨੂੰ ਸਮਾਰਟ ਦੁਕਾਨ ਫਰੈਂਚਾਈਜ਼ ਕਾਰੋਬਾਰ 'ਤੇ ਪੂਰਾ ਧਿਆਨ ਦੇਣਾ ਚਾਹੀਦਾ ਹੈ, ਤੁਹਾਨੂੰ ਇਸ ਕਾਰੋਬਾਰ ਤੋਂ ਵਧੀਆ ਨਤੀਜੇ ਪ੍ਰਾਪਤ ਹੋਣਗੇ।"
      },
      {
        q:"ਮੇਰੇ ਖੇਤਰ ਵਿਚ ਇਕ ਸਮਾਰਟ ਦੁਕਾਨ ਪਹਿਲਾਂ ਤੋਂ ਮੌਜੂਦ ਹੈ। ਕੀ ਮੈਂ ਇਸ ਆਉਟਲੈਟ ਦੇ ਨੇੜੇ ਆਪਣਾ ਇਕ ਹੋਰ ਫਰੈਂਚਾਈਜ਼ ਦੁਕਾਨ ਖੋਲ੍ਹ ਸਕਦਾ ਹਾਂ?",
        a:"ਸਾਡੀ ਨੀਤੀ ਦੇ ਅਨੁਸਾਰ ਅਸੀਂ ਹਰ ਫਰੈਂਚਾਇਜ਼ੀ ਦੇ ਵਪਾਰਕ ਹਿੱਤਾਂ ਦੀ ਰੱਖਿਆ ਕਰਦੇ ਹਾਂ। ਇਸ ਲਈ, ਕਿਸੇ ਮੌਜੂਦਾ ਫ੍ਰੈਂਚਾਈਜ਼ ਦੁਕਾਨ ਤੋਂ ਘੱਟ ਤੋਂ ਘੱਟ 2-3 ਕਿਲੋਮੀਟਰ ਦੀ ਦੂਰੀ 'ਤੇ ਅਜਿਹੀ ਜਗ੍ਹਾ ਦੀ ਚੋਣ ਕਰਨ ਦੀ ਸਲਾਹ ਦਿੱਤੀ ਜਾਂਦੀ ਹੈ । ਇਹ ਤੁਹਾਡੇ ਕਾਰੋਬਾਰ ਲਈ ਲਾਭਕਾਰੀ ਰਹੇਗਾ।"
      },
      {
        q:"ਕੀ ਮੈਂ ਇੱਕ ਚਲਾਨ ਵਿੱਚ 3 ਫੋਨ ਬਿੱਲਾਂ ਨੂੰ ਪੰਚ ਕਰ ਸਕਦਾ ਹਾਂ?",
        a:"ਨਹੀਂ, ਤੁਸੀਂ ਇੱਕ ਚਲਾਨ ਵਿੱਚ ਸਿਰਫ 1 ਫੋਨ ਦਾ  ਬਿੱਲ ਕੱਟ ਸਕਦੇ ਹੋ।"
      },
      {
        q:"ਕੀ ਮੈਂ ਆਪਣੇ ਵਾਲਿਟ ਦੇ ਪੈਸੇ ਆਪਣੇ ਬੈਂਕ ਦੇ ਖਾਤੇ ਵਿੱਚ ਟ੍ਰਾਂਸਫਰ ਕਰ ਸਕਦਾ ਹਾਂ?",
        a:"ਜਦੋਂ ਤਕ ਤੁਹਾਡਾ ਖਾਤਾ ਸਮਾਰਟ ਦੁਕਾਨ ਦੇ ਨਾਲ ਨਹੀਂ ਹੈ, ਉਦੋ ਤੱਕ ਤੁਸੀਂ ਆਪਣੇ ਵਾਲਿਟ ਦਾ ਪੈਸਾ ਅਪਣੇ ਬੈਂਕ ਖਾਤੇ ਵਿੱਚ ਟ੍ਰਾਂਸਫਰ ਨਹੀਂ ਕਰ ਸਕਦੇ ।"
      },
      {
        q:"ਕੀ ਮੈਂ ਆਪਣੇ ਸਮਾਰਟ ਦੁਕਾਨ ਐਪ ਨਾਲ ਰੀਚਾਰਜ ਕਰ ਸਕਦਾ ਹਾਂ?",
        a:"ਐਪ ਦੀ ਬਜਾਏ, ਅਸੀਂ ਤੁਹਾਨੂੰ ਤੁਹਾਡੇ ਡੈਸ਼ਬੋਰਡ ਦੁਆਰਾ ਅਜਿਹਾ ਕਰਨ ਦੀ ਸਿਫਾਰਸ਼ ਕਰਦੇ ਹਾਂ, ਜਿੱਥੇ ਤੁਸੀਂ ਰੀਅਲ-ਟਾਈਮ ਰੀਚਾਰਜ ਸਥਿਤੀ ਦੀ ਜਾਂਚ ਕਰ ਸਕਦੇ ਹੋ।"
      },
      {
        q:"ਜੇ ਬਿਲ ਗਲਤ ਤਰੀਕੇ ਨਾਲ ਦਾਖਲ ਹੋਏ ਹਨ ਤਾਂ ਗ੍ਰਾਹਕਾਂ ਦੇ ਵੇਰਵੇ ਕਿਵੇਂ ਬਦਲਣੇ ਹਨ?",
        a:"ਅਜਿਹੀਆਂ ਤਬਦੀਲੀਆਂ ਕਰਨ ਲਈ, ਤੁਹਾਨੂੰ ਸਮਾਰਟ ਦੁਕਾਨ ਦੇ ਕਸਟਮਰ ਕੇਅਰ ਨੂੰ ਸੂਚਿਤ ਕਰਨਾ ਪਏਗਾ ਅਤੇ ਸਹੀ ਗਾਹਕ ਵੇਰਵਿਆਂ ਨੂੰ care@smartdukaan.com ਤੇ ਭੇਜਣਾ ਪਏਗਾ। ਇਸ ਤੋਂ ਇਲਾਵਾ, ਤੁਸੀਂ ਆਪਣੇ ਖੇਤਰ ਦੇ ਬੀ ਡੀ ਐੱਮ ਨਾਲ ਵੀ ਸੰਪਰਕ ਕਰ ਸਕਦੇ ਹੋ।"
      },
      {
        q:"ਜੇ ਗਾਹਕ ਖਰੀਦਾਰੀ ਨਹੀਂ ਕਰਨਾ ਚਾਹੁੰਦਾ ਤਾਂ ਚਲਾਨ ਕਿਵੇਂ ਰੱਦ ਕਰਨਾ ਹੈ?",
        a:"ਤੁਹਾਨੂੰ ਰੱਦ ਹੋਣ ਦਾ ਕਾਰਨ ਦੱਸਦੇ ਹੋਏ ਇੱਕ ਮੇਲ care@smartdukaan.com ਨੂੰ ਭੇਜਣੀ ਪਵੇਗੀ। ਇਸ ਤੋਂ ਇਲਾਵਾ, ਤੁਸੀਂ ਆਪਣੇ ਖੇਤਰ ਦੇ ਬੀ ਡੀ ਐੱਮ ਨਾਲ ਵੀ ਸੰਪਰਕ ਕਰ ਸਕਦੇ ਹੋ।"
      },
      {
        q:"ਕੀ ਮੈਂ ਵਿਅਕਤੀਗਤ ਤੌਰ 'ਤੇ ਡੈਮੋ ਫੋਨ ਦੀ ਵਰਤੋਂ ਕਰ ਸਕਦਾ ਹਾਂ?",
        a:"ਬ੍ਰਾਂਡ ਤੁਹਾਨੂੰ ਕਾਉਂਟਰ ਉੱਤੇ ਪ੍ਰਦਰਸ਼ਿਤ ਕਰਨ ਲਈ ਡੈਮੋ ਫੋਨ ਦਿੰਦੇ ਹਨ। ਤੁਸੀਂ ਇਨ੍ਹਾਂ ਫੋਨਾਂ ਨੂੰ 3 ਮਹੀਨਿਆਂ ਲਈ ਨਾ ਵੇਚ ਸਕਦੇ ਹੋ ਨਾ ਹੀ  ਇਹਨਾਂ ਨੂੰ ਐਕਟੀਵੇਟ ਕਰ ਸਕਦੇ ਹੋ।"
      },

      {
        q:"ਫੋਨ ਵੇਚਣ ਦੇ 2-3 ਦਿਨਾਂ ਦੇ ਅੰਦਰ ਅਗਰ ਖਰਾਬ ਹੋ ਜਾਂਦਾ ਹੈ, ਤੇ ਕੀ ਕਿੱਤਾ ਜਾ ਸਕਦਾ ਹੈ ?",
        a:"ਡੀਓਏ ਪੱਤਰ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਗਾਹਕ ਨੂੰ ਲਾਜ਼ਮੀ ਨਜ਼ਦੀਕੀ ਸੇਵਾ ਕੇਂਦਰ ਜਾਣਾ ਪਵੇਗਾ। ਜਦੋਂ ਡੀਓਏ ਪੱਤਰ ਜਾਰੀ ਕੀਤਾ ਜਾਂਦਾ ਹੈ, ਤਾਂ ਅਸੀਂ ਫੋਨ ਨੂੰ ਬ੍ਰਾਂਡ ਦੇ ਕੋਲੋਂ ਬਦਲ ਸਕਦੇ ਹਾਂ।"
      },
      {
        q:"ਜੇ ਤੁਹਾਨੂੰ ਮਾੜਾ ਕੋਰੀਅਰ ਬਾਕਸ ਮਿਲ ਜਾਵੇ ਤਾਂ ਕੀ ਕਰਨਾ ਹੈ?",
        a:"ਆਪਣੇ ਬੀਡੀਐਮ / ਆਰਬੀਐਮ ਨੂੰ ਤੁਰੰਤ ਸੂਚਿਤ ਕਰੋ ਅਤੇ ਪੀਓਡੀ ਸਲਿੱਪ 'ਤੇ ਸਮੱਸਿਆ ਦੀ ਰਿਪੋਰਟ ਕਰਨੀ ਪਵੇਗੀ।"
      },
      {
        q:"ਆਪਣੇ ਬੀਡੀਐਮ / ਆਰਬੀਐਮ ਨੂੰ ਤੁਰੰਤ ਸੂਚਿਤ ਕਰੋ ਅਤੇ ਪੀਓਡੀ ਸਲਿੱਪ 'ਤੇ ਸਮੱਸਿਆ ਦੀ ਰਿਪੋਰਟ ਕਰਨੀ ਪਵੇਗੀ।",
        a:"ਤੁਹਾਨੂੰ ਜੀਐਸਟੀ ਦੇ ਦੂਜੇ ਪੇਜ 'ਤੇ ਆਪਣੇ ਕਾਰੋਬਾਰ ਦੀ ਜਗ੍ਹਾ ਨੂੰ ਜੋੜਨ ਦੀ ਬੇਨਤੀ ਕਰਨੀ ਪਵੇਗੀ। ਕਾਰੋਬਾਰ ਦੀ ਜਗ੍ਹਾ ਜੋੜਨ ਤੋਂ ਬਾਅਦ, ਅਸੀਂ ਤੁਹਾਡੇ ਰਜਿਸਟਰਡ ਪਤੇ ਨੂੰ ਬਦਲ ਸਕਦੇ ਹਾਂ ।"
      },



    ]}]


    for (let qaSection of this.punqas) {
      qaSection.clicked = false;
      for (let qa of qaSection) {
        qa.clicked = false;
      }
    }


  }

  ngOnInit() {
  }

  navigateBack() {
    this._location.back();
  }


  onSelectChange(event){
    if (event.index == 0) {
      this.english = true;
     this.hindi=false;
     this.punjabi=false
    } else if(event.index == 1){
      this.english = false;
      this.hindi=true;
      this.punjabi=false
    }else{

      this.english = false;
      this.hindi=false;
      this.punjabi=true
    }


  }

}
