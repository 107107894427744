import { Injectable } from '@angular/core';
import {Constants} from "./constants";
import { Subject } from 'rxjs/Subject';

@Injectable()
export class CommonService extends Constants {

  constructor(){
    super();
  }

  queryStream = new Subject<any>();

  searchEventStream = new Subject<String>();

  pushSubject = new Subject<any>();

  stringParams(params: any): string{
    let x = [];
    for(let k in  params) {
      if(typeof params[k] != "undefined") {
        x.push(k+"="+params[k]);
      }
    }
    return x.join('&');

  }

  setStorage(key,value): any{
    if(typeof value == "object"){
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  };
  getStorage(key): any{
    var x = localStorage.getItem(key);
    try {
      var xx = JSON.parse(x)
      return xx;
    } catch(e) {
      return x;
    }
  };
  removeStorage(key): any{
    localStorage.removeItem(key);
  };
  removeAllStorage(): any{
    localStorage.clear();
  };

  getMappedComboModel(catalogId:number) {
    let comboCatalogMap = this.getStorage("comboModel");
    if(comboCatalogMap!=null && catalogId in comboCatalogMap) {
      return comboCatalogMap[catalogId];
    } else {
      return null;
    }
  }
  addMappedComboModel(catalogId, comboCatalogId) {
    let comboCatalogMap = this.getStorage("comboModel");
    if(comboCatalogMap !=null && catalogId in comboCatalogMap) {
      return false;
    } else {
      comboCatalogMap = comboCatalogMap || {};
      comboCatalogMap[catalogId] = comboCatalogId;
      this.setStorage('comboModel', comboCatalogMap);
    }
  }
  removeMappedComboModel(catalogId) {
    let comboCatalogMap = this.getStorage("comboModel");
    if(comboCatalogMap != null && catalogId in comboCatalogMap) {
      delete comboCatalogMap[catalogId];
      this.setStorage('comboModel', comboCatalogMap);
    } else {
      return false;
    }

  }





  getData(){
    return this.getStorage(this.registered)
  };
  getToken(){
    let x = this.getStorage(this.newToken)
    if(x !=null && x != 'undefined'){
      return x;
    } else {
      return this.getStorage(this.startToken);
    }
  };
  getPincode(){
    return this.getStorage(this.defaultAddress).pinCode || "110093";
  };

  //unused
  setPincode(pin) {
    this.setStorage(this.pincode, pin);
  };

  isRetailer() {
    if('userStatus' in this.getData()) {
        return this.checkRetailer.indexOf(this.getData()['userStatus']) != -1
    }
    return false;
  }

  isFofo() {
    if('userStatus' in this.getData()) {
        return this.checkFofo.indexOf(this.getData()['userStatus']) != -1
    }
    return false;
  }

  isFofoAssociate(){
    if('userStatus' in this.getData()) {
      return this.checkFofoAssociate.indexOf(this.getData()['userStatus']) != -1
  }
  return false;
  }
  showTimeLine(){
    return this.getStorage(this.timelineStatus)=="true";
  }


  getAndroidId() {
    return this.getStorage(this.androidId) || '54e9d8231b74c46';
  }
  setAndroidId(id) {
    this.setStorage(this.androidId, id);
  }



  getDeviceId() {
    return this.getStorage(this.deviceId) || '97591';
  }
  setDeviceId(id) {
    this.setStorage(this.deviceId, id);
  }

}


