import {Component, OnInit, ViewChild} from '@angular/core';
import {ToastService} from "../../common/toast.service";
import {MobileOtpService} from "../mobile-otp/mobile-otp.service";
import {CommonService} from "../../common/common.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-mobile-verify',
  templateUrl: './mobile-verify.component.html',
  styleUrls: ['./mobile-verify.component.css']
})
export class MobileVerifyComponent implements OnInit {

  public verifyOtp:boolean = false;
  @ViewChild("OTPSubmit") OTPSubmit;
  public user: any = {};
  public otpChain:any = {};
  public verify: any = {};
  public loading:boolean = false;
  public loadingOTP:boolean = false;
  public oForm:boolean = true;
  constructor(private toast: ToastService, private _service: MobileOtpService, private common: CommonService, private _activatedRoute: ActivatedRoute, private _router:Router) {
    _activatedRoute.queryParams.subscribe(
      params => {
        console.log('queryParams', params['email'])
        this.verify.email = params['email'];
        this.verify.phone = params['phone'];
        this.verify.reference_id = params['reference_id'];
      }
    );
    this.setOtp();
  }
  next(el,pel, cel, e) {
    if(cel.value.length>0){
      return false;
    }
    if(46<e.keyCode && e.keyCode<58){
      if(el!=null)
        el.focus();
    } else{
      return false;
    };
  }
  keyUp(el,pel, cel,e){
    if(e.keyCode == 8){
      console.log(pel != null)
      if(pel != null){
        if(cel.value.length==0){
          pel.focus();
          pel.select();
        }

      }
    }
  }

  ngOnInit() {
  }

  submit(){}

  public otpVerification(){
    this.loadingOTP = true;
    var temp = "";
    for(var k in this.otpChain){
      temp += this.otpChain[k];
    }
    this.verify.otp_number = temp;
    console.log(this.verify);
    let obj = Object.assign(this.verify, this.user);
    this._service.verifyOTP(this.common.stringParams(this.verify))
      .subscribe(data => {
        this.loadingOTP = false;
        console.log("data",data['responseStatus'].toLowerCase() == 'success', data)
        if(data['responseStatus'].toLowerCase() == 'success'){
          if(data['response']['result'] == true){
            this.toast.success(data['response']['message']);
            this._router.navigate(['/pages/registration'], { queryParams: obj });
          } else {
            this.toast.error(data['response']['message'])
          }
        } else {
          this.toast.error("Server error")
        }
      }, error => {
        this.loadingOTP = false;
        if(error.json().statusCode == 400){
        this.toast.error(error.json().response.message)
        console.log("error",error.json())
         }
      else{
         console.log("error",error.json())
         this.toast.error("Something went wrong")
      }
      });

  };


  //  For android
  setOtp(){
    let x = setInterval(() => {
      let otp = this.common.getStorage(this.common.androidOtp);
      if(otp != null){
        try{
          this.otpChain.otp1 = otp;
          this.common.removeStorage(this.common.androidOtp);
        } catch(e){
          alert("OTP Fetching Error");
        }
          clearInterval(x);
          this.OTPSubmit.click();
      }
    },1000)
  }

}
